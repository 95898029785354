import { useLocation } from "react-router-dom";

import {
  BtnActionsNavbarWrapper,
  BtnProfileWrap,
  IconMenu,
  ImgMedic,
  LogoImage,
  LogoWrapper,
  NavBarContainer,
  BtnLogin,
} from "./NavBarElements";
import logoImg from "../../images/logo.svg";
import imgMedic from "../../images/medico.svg";
import useUser from "../../hooks/useUser";

const NavBar = ({ toggle, toggleP }) => {
  const location = useLocation();
  const {
    auth,
    patient: { selPatient },
    historia: { selHistory },
    evolution: { selEvolution },
  } = useUser();
  const subject =
    location.pathname === "/pacientes"
      ? " - Pacientes"
      : location.pathname === "/historias"
      ? " - Historias"
      : location.pathname === "/evoluciones"
      ? " - Evoluciones"
      : "";

  return (
    <>
      <NavBarContainer>
        <IconMenu onClick={toggle} />
        <LogoWrapper
          to="/"
          onClick={() => {
            selPatient([]);
            selHistory([]);
            selEvolution([]);
          }}
        >
          <LogoImage src={logoImg} alt="logo_cemedsys" />
          <h1>{subject}</h1>
        </LogoWrapper>
        <BtnActionsNavbarWrapper>
          {auth ? (
            <BtnProfileWrap>
              <ImgMedic onClick={toggleP} src={imgMedic} alt="img_medic" />
            </BtnProfileWrap>
          ) : location.pathname !== "/signin" ? (
            <BtnLogin to="/signin">Iniciar Sesión</BtnLogin>
          ) : (
            ""
          )}
        </BtnActionsNavbarWrapper>
      </NavBarContainer>
    </>
  );
};

export default NavBar;
