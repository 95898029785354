export const INIT = "INIT";
export const LOADING = "LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";

export const PATIENT_SUCCESS = "PATIENT_SUCCESS";
export const HIST_SUCCESS = "HIST_SUCCESS";
export const EVO_SUCCESS = "EVO_SUCCESS";
export const CITA_SUCCESS = "CITA_SUCCESS";
