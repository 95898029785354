import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import moment from "moment";

import NavBar from "./components/NavBar";
import SideBar from "./components/SideBar";
import ProfileBar from "./components/ProfileBar";

import UserState from "./context/User/UserState";

import Routes from "./components/routes";

import "react-toastify/dist/ReactToastify.css";

moment.updateLocale("es", {
  months: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split(
    "_"
  ),
  monthsShort: "ene._feb._mar_abr._may_jun_jul._ago_sept._oct._nov._dic.".split(
    "_"
  ),
  weekdays: "domingo_lunes_martes_miercoles_jueves_viernes_sabado".split("_"),
  weekdaysShort: "dom._lun._mar._mier._jue._vier._sab.".split("_"),
  weekdaysMin: "do_lu_ma_mi_ju_vi_sa".split("_"),
  _isAMomentObject: true,
  _isUTC: false,
  _useUTC: false,
});

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenP, setIsOpenP] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsOpenP(false);
  };

  const toggleP = () => {
    setIsOpenP(!isOpenP);
    setIsOpen(false);
  };

  return (
    <UserState>
      <BrowserRouter>
        <NavBar toggle={toggle} toggleP={toggleP} />
        <SideBar isOpen={isOpen} toggle={toggle} />
        <ProfileBar isOpenP={isOpenP} toggleP={toggleP} />
        <Routes />
        <ToastContainer />
      </BrowserRouter>
    </UserState>
  );
}

export default App;
