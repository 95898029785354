import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  FcAreaChart,
  FcPrint,
  FcPlanner,
  FcRules,
  FcButtingIn,
  FcList,
} from "react-icons/fc";

export const BtnActionsContainer = styled.div`
  display: flex;
  background: #ffffff;

  a {
    text-decoration: none;
    border: none;
    outline: none;
  }
`;

export const BtnPatients = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 50px;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  transition: 1s;
  cursor: pointer;

  &:hover {
    width: 180px;
    border-radius: 5px;
  }

  &:hover span {
    opacity: 1;
    padding: 2px;
    margin-left: 10px;
    width: max-content;
  }
`;

export const IconPat = styled(FcButtingIn)`
  font-size: 20px;
`;

export const BtnHistory = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 50px;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  transition: 1s;
  cursor: pointer;

  &:hover {
    width: 180px;
    border-radius: 5px;
  }

  &:hover span {
    opacity: 1;
    padding: 2px;
    margin-left: 10px;
    width: max-content;
  }
`;

export const IconHist = styled(FcList)`
  font-size: 20px;
`;

export const BtnEvolutions = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 50px;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  transition: 1s;
  cursor: pointer;

  &:hover {
    width: 180px;
    border-radius: 5px;
  }

  &:hover span {
    opacity: 1;
    padding: 2px;
    margin-left: 10px;
    width: max-content;
  }
`;

export const IconEvo = styled(FcAreaChart)`
  font-size: 20px;
`;

export const LabelBtn = styled.span`
  width: 0px;
  overflow: hidden;
  opacity: 0;
  transition: 1.5s;
  text-align: left;
  font-size: 14px;
`;

export const BtnPrint = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 50px;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border: none;
  outline: none;
  transition: 1s;
  cursor: pointer;

  &:hover {
    width: 180px;
    border-radius: 5px;
  }

  &:hover span {
    padding: 2px;
    opacity: 1;
    margin-left: 10px;
    width: max-content;
  }
`;

export const IconPrint = styled(FcPrint)`
  font-size: 20px;
`;

export const BtnNewDate = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 50px;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  transition: 1s;
  cursor: pointer;

  &:hover {
    width: 180px;
    border-radius: 5px;
  }

  &:hover span {
    padding: 2px;
    opacity: 1;
    margin-left: 10px;
    width: max-content;
  }
`;

export const IconNewDate = styled(FcPlanner)`
  font-size: 20px;
`;

export const BtnCertif = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 50px;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border: none;
  outline: none;
  transition: 1s;
  cursor: pointer;

  &:hover {
    width: 180px;
    border-radius: 5px;
  }

  &:hover span {
    padding: 2px;
    opacity: 1;
    margin-left: 10px;
    width: max-content;
  }
`;

export const IconCertif = styled(FcRules)`
  font-size: 20px;
`;

export const BtnPrintReceta = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 50px;
  font-family: inherit;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border: none;
  outline: none;
  transition: 1s;
  cursor: pointer;

  &:hover {
    width: 180px;
    border-radius: 5px;
  }

  &:hover span {
    padding: 2px;
    opacity: 1;
    margin-left: 10px;
    width: max-content;
  }
`;

export const IconPrintReceta = styled.div`
  font-size: 16px;
`;

export const BtnPrintNotaEvo = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 50px;
  font-family: inherit;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border: none;
  outline: none;
  transition: 1s;
  cursor: pointer;

  &:hover {
    width: 180px;
    border-radius: 5px;
  }

  &:hover span {
    padding: 2px;
    opacity: 1;
    margin-left: 10px;
    width: max-content;
  }
`;

export const IconPrintNotaEvo = styled.div`
  font-size: 16px;
`;
