import { BtnAddContainer, IconAdd } from "./BtnAddElements.js";

const BtnAdd = ({ showForm }) => {
  return (
    <BtnAddContainer onClick={showForm}>
      <IconAdd />
    </BtnAddContainer>
  );
};

export default BtnAdd;
