import { useState } from "react";
import moment from "moment";

import {
  InformationContainer,
  InformationContent,
  InformationBit,
  InformationDetail,
  InformationData,
  InformationGroupData,
  InformationLabelData,
  InformationLabelField,
} from "../InformationtElements";
import BtnMore from "../../BtnMore";

import { calcEdad } from "../../../lib/utilies";

const InfoPatient = ({ patient }) => {
  const edad = calcEdad(patient.nacimiento);
  const [moreInfo, setMoreInfo] = useState(false);

  const handleShowInfo = () => setMoreInfo(!moreInfo);

  return (
    <InformationContainer>
      <InformationContent>
        <InformationBit>
          <InformationGroupData>
            <InformationLabelField>Nombre:</InformationLabelField>
            <InformationLabelData>{patient.name}</InformationLabelData>
          </InformationGroupData>
          <InformationGroupData>
            <InformationLabelField>Nacimiento:</InformationLabelField>
            <InformationLabelData>
              {moment(patient.nacimiento).format("DD/MM/YYYY")}
            </InformationLabelData>
          </InformationGroupData>
          <InformationGroupData>
            <InformationLabelField>Edad:</InformationLabelField>
            <InformationLabelData>{edad.edad}</InformationLabelData>
          </InformationGroupData>
        </InformationBit>
        {moreInfo && (
          <>
            <InformationDetail>
              <InformationGroupData>
                <InformationLabelField>Cedula:</InformationLabelField>
                <InformationLabelData>{patient.CI}</InformationLabelData>
              </InformationGroupData>
              <InformationGroupData>
                <InformationLabelField>Paciente:</InformationLabelField>
                <InformationLabelData>
                  {edad.num <= 14 ? "Pediátrico" : "Adulto"}
                </InformationLabelData>
              </InformationGroupData>
              <InformationGroupData>
                <InformationLabelField>Teléfono:</InformationLabelField>
                <InformationLabelData>{patient.phone}</InformationLabelData>
              </InformationGroupData>
              <InformationGroupData>
                <InformationLabelField>Email:</InformationLabelField>
                <InformationLabelData>{patient.email}</InformationLabelData>
              </InformationGroupData>
              <InformationGroupData>
                <InformationLabelField>Dirección:</InformationLabelField>
                <InformationLabelData>{patient.address}</InformationLabelData>
              </InformationGroupData>
            </InformationDetail>
            <InformationData>
              <InformationGroupData>
                <InformationLabelField>Paciende desde:</InformationLabelField>
                <InformationLabelData>
                  {moment(patient.registedAt).format("LL")}
                </InformationLabelData>
              </InformationGroupData>
              {/* <InformationGroupData>
                <InformationLabelField>
                  Fue actualizado el:
                </InformationLabelField>
                <InformationLabelData>
                  {moment(patient.updateAt).format("LLL")}
                </InformationLabelData>
              </InformationGroupData> */}
            </InformationData>
          </>
        )}
      </InformationContent>
      <BtnMore showInfo={handleShowInfo} />
    </InformationContainer>
  );
};

export default InfoPatient;
