import { useEffect, useState } from "react";

import {
  BtnActionsArea,
  BtnActionsButton,
  RecetasContainer,
  RecetasContent,
} from "./RecetasElements";
import Buscador from "../../components/Buscador";
import useUser from "../../hooks/useUser";
import BtnActions from "../../components/BtnActions";
import FormReceta from "../../components/FormReceta";
import ViewPresc from "../../components/ViewPresc";

const Recetas = () => {
  const URIROOT = process.env.REACT_APP_URIROOT;
  const {
    token,
    patient: { patient },
    user: { cm },
  } = useUser();

  const [patients, setPatients] = useState([]);
  const [doctores, setDoctores] = useState([]);
  const [dcm, setDCM] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isQuery, setIsQuery] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };

    const fetchCM = async () => {
      const res = await fetch(`${URIROOT}/cm/${cm}`, config);
      const datacm = await res.json();

      setDCM(datacm.data[0]);
    };

    const fetchDoctores = async () => {
      const res = await fetch(`${URIROOT}/doctor/all/${cm}`, config);
      const data = await res.json();

      setDoctores(data.data);
    };

    const fetchPatients = async () => {
      const res = await fetch(`${URIROOT}/patient/all/${cm}`, config);
      const data = await res.json();

      setPatients(data.data);
    };

    fetchPatients();
    fetchDoctores();
    fetchCM();

    if (patient._id !== undefined) {
      setIsAdd(true);
    } else if (patient._id === undefined) {
      setIsAdd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsAdd = () => {
    setIsAdd(true);
  };

  const handleNoIsAdd = () => {
    setIsAdd(false);
  };

  const RenderContent = () => {
    if (isQuery) {
      return <ViewPresc doctores={doctores} dcm={dcm} />;
    } else {
      return <FormReceta doctores={doctores} dcm={dcm} />;
    }
  };

  const handleClickView = () => setIsQuery(true);
  const handleClickForm = () => setIsQuery(false);

  return (
    <RecetasContainer>
      <Buscador
        patients={patients}
        formShow={handleIsAdd}
        formNoShow={handleNoIsAdd}
        show={isAdd}
      />
      <BtnActions />
      {isAdd && (
        <RecetasContent>
          <BtnActionsArea>
            <BtnActionsButton onClick={handleClickView}>
              Ver Recetas
            </BtnActionsButton>
            <BtnActionsButton onClick={handleClickForm}>
              Registrar Receta
            </BtnActionsButton>
          </BtnActionsArea>
          <RenderContent />
        </RecetasContent>
      )}
    </RecetasContainer>
  );
};

export default Recetas;
