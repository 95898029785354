import { useEffect } from "react";
import useSearchPatient from "../../hooks/useSearchPatient";
import useUser from "../../hooks/useUser";
import {
  BuscadorContainer,
  LabelBuscadorInput,
  InputBuscador,
  SearchArea,
  ResultadoContainer,
  ResultBuscador,
  ResultLi,
} from "./BuscadorElements";

import "./Buscador.css";

const Buscador = ({ patients, show, formShow, formNoShow, refSearch }) => {
  const [query, setQuery, filteredPatients] = useSearchPatient(patients);
  const {
    patient: { selPatient, patient },
    historia: { getHistoria, selHistory },
    evolution: { selEvolution },
  } = useUser();

  useEffect(() => {
    if (patient._id !== undefined) {
      setQuery(patient.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BuscadorContainer>
      <SearchArea>
        <LabelBuscadorInput>🔍</LabelBuscadorInput>
        <InputBuscador
          ref={refSearch}
          type="text"
          name="search"
          autoComplete="off"
          placeholder="Inserta nombre o la cedula del paciente"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            selPatient([]);
            selHistory([]);
            selEvolution([]);
            formNoShow();
          }}
        />
      </SearchArea>

      {query !== "" && !show && (
        <ResultadoContainer>
          <ResultBuscador>
            {filteredPatients.map((pat) => (
              <ResultLi
                key={pat._id}
                onClick={() => {
                  formShow();
                  selPatient(pat);
                  setQuery(pat.name);
                  getHistoria(pat._id);
                }}
              >
                {pat.name}
              </ResultLi>
            ))}
          </ResultBuscador>
        </ResultadoContainer>
      )}
    </BuscadorContainer>
  );
};

export default Buscador;
