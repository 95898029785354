import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import useUser from "../../hooks/useUser";
import {
  BtnActionsContainer,
  BtnPatients,
  IconPat,
  BtnHistory,
  IconHist,
  BtnEvolutions,
  IconEvo,
  LabelBtn,
  BtnPrint,
  IconPrint,
  BtnNewDate,
  IconNewDate,
  BtnCertif,
  IconCertif,
  BtnPrintReceta,
  IconPrintReceta,
  BtnPrintNotaEvo,
  IconPrintNotaEvo,
} from "./BtnActionsElements";
import { calcEdad } from "../../lib/utilies";

const BtnActions = () => {
  const {
    patient: { patient },
    historia: { historia, printHistory },
    evolution: { evolution, selEvolution, printEvolution },
    presc: { printPresc },
    user: { cm },
    token,
  } = useUser();

  const URIROOT = process.env.REACT_APP_URIROOT;

  const [dcm, setDCM] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [cevolution, setCEvolution] = useState([]);
  const [where, setWhere] = useState("");
  const location = useLocation();

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };

    const fetchCM = async () => {
      const res = await fetch(`${URIROOT}/cm/${cm}`, config);
      const datacm = await res.json();

      setDCM(datacm.data[0]);
    };

    const fetchEvolutions = async (id_patient) => {
      const evs = await fetch(
        `${URIROOT}/evolution/${cm}/${id_patient}`,
        config
      );

      const evolutions = await evs.json();

      if (evolutions.data.length > 0) {
        const evo = evolutions.data[0];
        setCEvolution(evo);

        setTimeout(() => {
          fetchDr(evo.id_doctor);
        }, 1000);
      }
    };

    const fetchDr = async (id_doctor) => {
      const res = await fetch(`${URIROOT}/doctor/${id_doctor}`, config);
      const datadr = await res.json();

      setDoctor(datadr.data[0]);
    };

    fetchEvolutions(patient._id);
    fetchCM();

    if (location.pathname === "/pacientes") {
      setWhere("pacientes");
    } else if (location.pathname === "/historias") {
      setWhere("historias");
    } else if (location.pathname === "/evoluciones") {
      setWhere("evoluciones");
      // fetchCM();
      if (evolution._id !== undefined) {
        fetchDr(evolution.id_doctor);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrintH = async () => {
    if (location.pathname === "/pacientes") {
      if (patient._id === undefined) {
        toast("Aun no has consultado un paciente", {
          autoClose: 2000,
          type: "warning",
        });
      }
    } else if (location.pathname === "/historias") {
      if (historia._id === undefined) {
        toast("No existe una historia registrada para imprimir", {
          autoClose: 2000,
          type: "warning",
        });
      } else if (cevolution._id !== undefined) {
        const idhist = `HIST${patient.CI}${String(
          moment().format("DDMMYYYY")
        )}`;
        const fecha = `${moment().format("DD")} de ${moment().format(
          "MMMM"
        )} del ${moment().format("YYYY")}`;
        const age = await calcEdad(patient.nacimiento).edad;
        const tipo = patient.ped ? "ped" : "adul";

        const data = {
          fecha,
          tipo,
          cmname: dcm.name,
          drname: doctor.name,
          dresp: doctor.especialidad,
          cmphone: dcm.phone,
          dremail: doctor.email,
          cmaddress: dcm.address,
          cmcity: dcm.city,
          cmcountry: dcm.country,
          codcm: dcm.codigo,
          idhist,
          name: patient.name,
          ci: patient.CI,
          email: patient.email,
          nacimiento: patient.nacimiento,
          age,
          address: patient.address,
          phone: patient.phone,
          alergias: historia.alergias,
          medicamentos: historia.medicamentos,
          transfusiones: historia.transfusiones,
          antecprenatales: historia.antecprenatales,
          antecneopost: historia.antecneopost,
          antecperpatologicos: historia.antecperpatologicos,
          antecpatfamiliares: historia.antecpatfamiliares,
          motivo: cevolution.motive,
          enfermactual: cevolution.enfermactual,
          exfisico: cevolution.exfisic,
          diagnostico: cevolution.diagnosis || [],
          notasDiag: cevolution.notesdiag,
          examenes: cevolution.exams || [],
          notasExam: cevolution.notesexlab,
          resexlab: cevolution.resexlab,
          peso: cevolution.peso,
          talla: cevolution.talla,
          imc: Number(cevolution.imc).toFixed(2),
          pcefalico: cevolution.pcefalico,
          temperatura: cevolution.temperatura,
          saturacion: cevolution.saturacion,
          frespiratoria: cevolution.frespiratoria,
          fcardiaca: cevolution.fcardiaca,
          tarterial: cevolution.tarterial,
          tmedicamentos: cevolution.tratamiento.medicamentos,
          tindicaciones: cevolution.tratamiento.indicaciones,
        };

        await printHistory(data);
      } else {
        toast("El paciente aun no tiene atenciones registradas", {
          autoClose: 2000,
          type: "warning",
        });
      }
    }
  };

  const handlePrintReceta = async () => {
    const idpresc = `PRE${patient.CI}${String(moment().format("DDMMYYYY"))}`;
    const fecha = `${moment().format("DD")} de ${moment().format(
      "MMMM"
    )} del ${moment().format("YYYY")}`;
    const age = await calcEdad(patient.nacimiento).edad;
    const tipo = patient.ped ? "ped" : "adul";
    const data = {
      tipo,
      cmname: dcm.name,
      drname: doctor.name,
      dresp: doctor.especialidad,
      drphone: doctor.phone,
      dremail: doctor.email,
      cmaddress: dcm.address,
      cmcity: dcm.city,
      cmcountry: dcm.country,
      codcm: dcm.codigo,
      idpresc,
      name: patient.name,
      ci: patient.CI,
      fecha,
      age,
      medicamentos: evolution.tratamiento.medicamentos,
      indicaciones: evolution.tratamiento.indicaciones,
      diagnostico: evolution.diagnosis,
      notasDiag: evolution.notesdiag,
    };

    await printPresc(data);
  };

  const handlePrintNotaEvo = async () => {
    const tipo = patient.ped ? "ped" : "adul";
    const idevo = `NEV${patient.CI}${String(moment().format("DDMMYYYY"))}`;
    const age = calcEdad(patient.nacimiento).edad;
    const fecha = `${moment().format("DD")} de ${moment().format(
      "MMMM"
    )} del ${moment().format("YYYY")}`;

    const data = {
      tipo,
      cmname: dcm.name,
      drname: doctor.name,
      dresp: doctor.especialidad,
      drphone: doctor.phone,
      dremail: doctor.email,
      cmaddress: dcm.address,
      codcm: dcm.codigo,
      idevo,
      name: patient.name,
      ci: patient.CI,
      email: patient.email,
      nacimiento: patient.nacimiento,
      age,
      address: patient.address,
      phone: patient.phone,
      fecha,
      motivo: evolution.motive,
      enfermactual: evolution.enfermactual,
      exfisico: evolution.exfisic,
      diagnostico: evolution.diagnosis,
      notasDiag: evolution.notesdiag,
      examenes: evolution.exams,
      notasExam: evolution.notesexlab,
      resexlab: evolution.resexlab,
      peso: evolution.peso === "" ? "0" : evolution.peso,
      talla: evolution.talla === "" ? "0" : evolution.talla,
      imc: evolution.imc === "" ? "0" : evolution.imc,
      pcefalico: evolution.pcefalico === "" ? "0" : evolution.pcefalico,
      temperatura: evolution.temperatura === "" ? "0" : evolution.temperatura,
      saturacion: evolution.saturacion === "" ? "0" : evolution.saturacion,
      frespiratoria:
        evolution.frespiratoria === "" ? "0" : evolution.frespiratoria,
      fcardiaca: evolution.fcardiaca === "" ? "0" : evolution.fcardiaca,
      tarterial: evolution.tarterial === "" ? "0" : evolution.tarterial,
      tmedicamentos: evolution.tratamiento.medicamentos,
      tindicaciones: evolution.tratamiento.indicaciones,
    };

    await printEvolution(data);
  };

  return (
    <BtnActionsContainer>
      {where !== "pacientes" && (
        <BtnPatients to="/pacientes" onClick={() => selEvolution([])}>
          <IconPat />
          <LabelBtn>Pacientes</LabelBtn>
        </BtnPatients>
      )}
      {where !== "historias" && (
        <BtnHistory to="/historias" onClick={() => selEvolution([])}>
          <IconHist />
          <LabelBtn>Historias</LabelBtn>
        </BtnHistory>
      )}
      {where !== "evoluciones" && (
        <BtnEvolutions to="/evoluciones" onClick={() => selEvolution([])}>
          <IconEvo />
          <LabelBtn>Evoluciones</LabelBtn>
        </BtnEvolutions>
      )}
      {historia._id !== undefined && where === "historias" && (
        <BtnPrint onClick={handlePrintH}>
          <IconPrint />
          <LabelBtn>Imprimir Historia</LabelBtn>
        </BtnPrint>
      )}
      <BtnNewDate to="/citas">
        <IconNewDate />
        <LabelBtn>Agendar Cita</LabelBtn>
      </BtnNewDate>
      {evolution._id !== undefined && where === "evoluciones" && (
        <BtnCertif to="/certificados">
          <IconCertif />
          <LabelBtn>Certificados</LabelBtn>
        </BtnCertif>
      )}
      {evolution._id !== undefined && where === "evoluciones" && (
        <BtnPrintReceta onClick={handlePrintReceta}>
          <IconPrintReceta>💊</IconPrintReceta>
          <LabelBtn>Imprimir Receta</LabelBtn>
        </BtnPrintReceta>
      )}
      {evolution._id !== undefined && where === "evoluciones" && (
        <BtnPrintNotaEvo onClick={handlePrintNotaEvo}>
          <IconPrintNotaEvo>📃</IconPrintNotaEvo>
          <LabelBtn>Nota Evolución</LabelBtn>
        </BtnPrintNotaEvo>
      )}
    </BtnActionsContainer>
  );
};

export default BtnActions;
