import {
  LOADING,
  INIT,
  LOGIN_SUCCESS,
  SUCCESS,
  FAIL,
  PATIENT_SUCCESS,
  HIST_SUCCESS,
  EVO_SUCCESS,
  CITA_SUCCESS,
} from "../types";

const reducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case INIT:
      return {
        user: [],
        auth: false,
        loading: false,
        token: null,
        message: null,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        auth: true,
        loading: false,
        token: payload.token,
        user: payload.user,
        message: payload.message,
      };
    case SUCCESS:
      return {
        ...state,
        message: payload.message,
        loading: false,
      };
    case FAIL:
      return {
        ...state,
        auth: false,
        loading: false,
        token: null,
        message: payload.message,
      };
    case PATIENT_SUCCESS:
      return {
        ...state,
        patient: payload.patient,
        loading: false,
        message: payload.message,
      };
    case HIST_SUCCESS:
      return {
        ...state,
        loading: false,
        historia: payload.historia,
        message: payload.message,
      };
    case EVO_SUCCESS:
      return {
        ...state,
        loading: false,
        evolution: payload.evolution,
        message: payload.message,
      };
    case CITA_SUCCESS:
      return {
        ...state,
        loading: false,
        cita: payload.cita,
        message: payload.message,
      };

    default:
      return state;
  }
};

export default reducer;
