import styled from "styled-components";

export const ViewPrescContainer = styled.div`
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
`;

export const ViewPrescList = styled.ul``;

export const ViewPrescListItem = styled.li`
  list-style: none;
  padding: 10px 10px;
  box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 15px 15px;
  border: none;

  &:hover {
    background: #91ffff;
  }
`;

export const ViewPrescListItemNull = styled.li`
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 10px 10px;
  box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 15px 15px;
  border: none;
`;
