import { ListEvolutionsContainer } from "./ListEvolutionsElements";
import CardEvo from "../CardEvo";

const ListEvolutions = ({ evolutions, showFormToUpdate }) => {
  return (
    <ListEvolutionsContainer>
      {evolutions.map((evo, i) => {
        return (
          <CardEvo
            evolution={evo}
            key={i}
            showFormToUpdate={showFormToUpdate}
          />
        );
      })}
    </ListEvolutionsContainer>
  );
};

export default ListEvolutions;
