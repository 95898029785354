import moment from "moment";

import {
  MedicImg,
  MedicImgWrap,
  ProfileBarContainer,
  ProfileInfoWrapper,
  BtnActionWrap,
  BtnActionLogout,
  TextProfileInfo,
  InfoNombreUsuario,
  InfoDetailProfile,
} from "./ProfileBarElements";

import imgMedic from "../../images/medico.svg";

import useUser from "../../hooks/useUser";

const ProfileBar = ({ isOpenP, toggleP }) => {
  const { user, logout } = useUser();
  const perfil =
    user.profile === "A"
      ? "Administrador"
      : user.profile === "D"
      ? "Doctor"
      : "Asistente Médico";

  const handleClick = async (e) => {
    e.preventDefault();
    await logout();
  };

  return (
    <>
      <ProfileBarContainer isOpenP={isOpenP} onClick={toggleP}>
        <ProfileInfoWrapper>
          <MedicImgWrap>
            <MedicImg src={imgMedic} alt="img_medic" />
          </MedicImgWrap>
          <TextProfileInfo>
            <InfoNombreUsuario>{user.username}</InfoNombreUsuario>
            <InfoDetailProfile>
              {perfil} <br />
              {moment().format("LLLL")}
            </InfoDetailProfile>
          </TextProfileInfo>
        </ProfileInfoWrapper>
        <BtnActionWrap>
          <BtnActionLogout onClick={handleClick}>Cerrar Sesión</BtnActionLogout>
        </BtnActionWrap>
      </ProfileBarContainer>
    </>
  );
};

export default ProfileBar;
