import styled from "styled-components";

export const BuscadorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 60%;
  padding: 10px 10px;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const SearchArea = styled.div`
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  /* box-shadow: 5px 7px 11px -6px #000000; */
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
`;

export const LabelBuscadorInput = styled.div`
  padding: 5px 20px;
  border: none;
  width: 10%;
  text-align: center;
  border-radius: 10px 0 0 10px;

  background: #8cf1dd; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #78ffd6,
    #8cf1dd
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #78ffd6,
    #8cf1dd
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  @media screen and (max-width: 900px) {
    padding: 5px 0;
  }
`;

export const InputBuscador = styled.input`
  padding: 5px 20px;
  font-family: inherit;
  width: 90%;
  border: 1px solid #fbfbfb;
  /* box-shadow: 5px 7px 11px -6px #000000; */

  background: #fff;
  box-sizing: border-box;
  outline: none;
  border-radius: 0 10px 10px 0;
`;

export const ResultadoContainer = styled.div`
  width: 60%;
  z-index: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: absolute;
  top: 19%;

  @media screen and (max-width: 480px) {
    top: 15%;
    width: 90%;
    align-items: center;
  }

  @media screen and (max-width: 375px) {
    top: 17%;
    width: 90%;
  }

  @media screen and (max-width: 320px) {
    top: 20%;
    width: 90%;
  }

  @media screen and (max-width: 280px) {
    top: 17%;
    width: 90%;
  }
`;

export const ResultBuscador = styled.ul``;

export const ResultLi = styled.li`
  border-radius: 15px;
  list-style: none;
  cursor: pointer;
  padding: 8px 30px;
  border: 1px solid #f1f0f0;
  margin: 5px 0;
  font-size: 14px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
`;
