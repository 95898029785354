import styled from "styled-components";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

export const BtnMoreContainer = styled.div`
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 1s;
`;

export const IconMore = styled(FaAngleDown)`
  font-size: 25px;
  color: #afafaf;
`;

export const IconLess = styled(FaAngleUp)`
  font-size: 25px;
  color: #afafaf;
`;
