import { useEffect, useState } from "react";

import {
  HistoriasContainer,
  MenuHistorias,
  HistoriaContent,
  HistoriaForm,
} from "./HistoriasElements";
import useUser from "../../hooks/useUser";

import Buscador from "../../components/Buscador";
import InfoPatient from "../../components/Information/InfoPatient";
import FormHistory from "../../components/FormHistory";
import BtnActions from "../../components/BtnActions";

const Historias = () => {
  const {
    user: { cm },
    patient: { patient },
    historia: { historia },
    token,
  } = useUser();

  const [isAdd, setIsAdd] = useState(false);
  const [patients, setPatients] = useState([]);
  const URIROOT = process.env.REACT_APP_URIROOT;

  const fetchPatients = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };
    const res = await fetch(`${URIROOT}/patient/all/${cm}`, config);
    const data = await res.json();

    setPatients(data.data);
  };

  useEffect(() => {
    fetchPatients();

    if (patient._id !== undefined) {
      setIsAdd(true);
    } else if (patient._id === undefined) {
      setIsAdd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsAdd = () => {
    setIsAdd(true);
  };

  const handleNoIsAdd = () => {
    setIsAdd(false);
  };

  return (
    <HistoriasContainer>
      <MenuHistorias>
        <Buscador
          patients={patients}
          formShow={handleIsAdd}
          formNoShow={handleNoIsAdd}
          show={isAdd}
        />
        {/* Aqui va BtnActions */}
      </MenuHistorias>
      <BtnActions />
      {isAdd && (
        <HistoriaContent>
          <HistoriaForm>
            <InfoPatient patient={patient} />
            <FormHistory history={historia} />
          </HistoriaForm>
        </HistoriaContent>
      )}
    </HistoriasContainer>
  );
};

export default Historias;
