import styled from "styled-components";
import { Link } from "react-router-dom";

export const FormPatientContainer = styled.form`
  width: 100%;

  @media screen and (max-width: 480px) {
    padding: 5px 8px;
  }
`;

export const FormTitleArea = styled.h1`
  font-size: 16px;
  margin: 0;
`;

export const FormGroupCN = styled.div`
  margin: 5px 0;
  text-align: left;
  padding: 5px;
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-template-rows: 1fr;
  width: 100%;

  @media screen and (max-width: 480px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

export const FormGroup = styled.div`
  text-align: left;
  padding: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LabelForm = styled.label`
  font-size: small;
  color: gray;
`;

export const InputForm = styled.input`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
  background: ${({ isBtn }) => (isBtn ? "#d2ffee" : "#fff")};
`;

export const FormGroupRow3 = styled.div`
  margin: 5px 0;
  text-align: left;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;

  @media screen and (max-width: 480px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

export const FormGroupRow = styled.div`
  margin: 5px 0;
  text-align: left;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;

  @media screen and (max-width: 480px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

export const TextAreaForm = styled.textarea`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
`;

export const BtnActionsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

export const BtnGuardar = styled.button`
  cursor: pointer;
  text-decoration: none;
  width: 20%;
  padding: 10px 20px;
  font-family: inherit;
  font-size: 14px;
  text-align: center;
  background: #5ac8fa;
  border-radius: 10px;
  border: none;
  /* box-shadow: 5px 7px 11px -6px #000000; */
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    width: 40%;
  }
`;

export const BtnCancelar = styled(Link)`
  margin-left: 20px;
  cursor: pointer;
  width: 20%;
  background: #eee;
  text-decoration: none;
  padding: 10px 20px;
  color: #757575;
  border: none;
  border-radius: 10px;
  /* box-shadow: 5px 7px 11px -6px #000000; */
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    width: 40%;
  }
`;

export const SmallText = styled.small`
  font-size: 10px;
  color: gray;
`;
