import {
  SidebarContainer,
  IconClose,
  IconSettings,
  SettingsIcon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBarBtnAction,
  IconHistoria,
  IconEvolucion,
  IconRecetas,
  IconCita,
  IconPaciente,
} from "./SideBarElements";
import useUser from "../../hooks/useUser";

import patient from "../../images/patient.svg";
import cita from "../../images/schedule.svg";

const SideBar = ({ isOpen, toggle }) => {
  const {
    patient: { selPatient },
    historia: { selHistory },
    evolution: { selEvolution },
  } = useUser();
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <SideBarBtnAction>
        <IconSettings onClick={toggle} to="/ajustes">
          <SettingsIcon />
        </IconSettings>
        <IconClose onClick={toggle}>
          <CloseIcon />
        </IconClose>
      </SideBarBtnAction>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            onClick={() => {
              toggle();
              selPatient([]);
              selHistory([]);
              selEvolution([]);
            }}
            to="/pacientes"
          >
            <IconPaciente src={patient} /> Pacientes
          </SidebarLink>
          <SidebarLink
            onClick={() => {
              toggle();
              selPatient([]);
              selHistory([]);
              selEvolution([]);
            }}
            to="/historias"
          >
            <IconHistoria /> Historias
          </SidebarLink>
          <SidebarLink
            onClick={() => {
              toggle();
              selPatient([]);
              selHistory([]);
              selEvolution([]);
            }}
            to="/evoluciones"
          >
            <IconEvolucion /> Evoluciones
          </SidebarLink>
          <SidebarLink
            onClick={() => {
              toggle();
              selPatient([]);
              selHistory([]);
              selEvolution([]);
            }}
            to="/recetas"
          >
            <IconRecetas /> Recetas
          </SidebarLink>
          <SidebarLink
            onClick={() => {
              toggle();
              selPatient([]);
              selHistory([]);
              selEvolution([]);
            }}
            to="/citas"
          >
            <IconCita src={cita} /> Citas
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default SideBar;
