import styled from "styled-components";

export const BuscaPatContainer = styled.div`
  width: 100%;
`;

export const InputForm = styled.input`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
  background: ${({ isBtn }) => (isBtn ? "#d2ffee" : "#fff")};
`;

export const ResultContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid gray; */
  border: none;
  z-index: 1;
  box-shadow: 0px 9px 13px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 5px;
  font-size: 12px;
`;

export const ListResults = styled.ul``;

export const ItemResult = styled.li`
  list-style: none;
  cursor: pointer;
  padding: 10px 10px;

  &:hover {
    background: #eae5e5;
  }
`;
