import { useEffect, useState } from "react";

import FormCitas from "../../components/FormCitas";
import ListCitas from "../../components/ListCitas";
import useUser from "../../hooks/useUser";
import {
  CitasContainer,
  CitasContent,
  CitasForm,
  CitasList,
} from "./CitasElements";

const Citas = () => {
  const URIROOT = process.env.REACT_APP_URIROOT;
  const {
    token,
    user: { cm },
  } = useUser();
  const [patients, setPatients] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [doctores, setDoctores] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };

    const fetchCitas = async () => {
      const res = await fetch(`${URIROOT}/schedule/all/${cm}`, config);
      const data = await res.json();

      const datePendings = data.data.filter(
        (date) => new Date(date.date) >= new Date()
      );

      // order by date desc
      const datasort = datePendings.sort((a, b) => {
        if (a.date > b.date) {
          return 1;
        }
        if (a.date < b.date) {
          return -1;
        }
        return 0;
      });

      setSchedule(datasort);
    };

    const fetchPatients = async () => {
      const res = await fetch(`${URIROOT}/patient/all/${cm}`, config);
      const data = await res.json();

      setPatients(data.data);
    };

    const fetchDoctores = async () => {
      const res = await fetch(`${URIROOT}/doctor/all/${cm}`, config);
      const data = await res.json();

      setDoctores(data.data);
    };

    fetchCitas();
    fetchPatients();
    fetchDoctores();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CitasContainer>
      <CitasContent>
        <CitasForm>
          <FormCitas patients={patients} doctores={doctores} />
        </CitasForm>
        <CitasList>
          <ListCitas citas={schedule} />
        </CitasList>
      </CitasContent>
    </CitasContainer>
  );
};

export default Citas;
