import styled from "styled-components";

export const HistoriasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
  overflow-y: auto;

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const MenuHistorias = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
`;

export const HistoriaContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

// Form
export const HistoriaForm = styled.div`
  width: 100%;
  padding: 10px 20px;
`;

export const FormGroupCN = styled.div`
  margin: 5px 0;
  text-align: left;
  padding: 5px;
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-template-rows: 1fr;
  width: 100%;

  @media screen and (max-width: 480px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

export const InputForm = styled.input`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
`;
