import { useMemo, useState } from "react";

const useSearchPatient = (patients) => {
  const [query, setQuery] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);

  useMemo(() => {
    const result = patients.filter(
      (patient) =>
        patient.name.toLowerCase().includes(query.toLowerCase()) ||
        patient.CI.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredPatients(result);
  }, [patients, query]);

  return [query, setQuery, filteredPatients];
};

export default useSearchPatient;
