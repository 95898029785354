import useUser from "../../hooks/useUser";
import {
  CardEvoContainer,
  CardHeader,
  CardBody,
  LabelCard,
  DataCard,
  GroupDataCard,
  DataListCard,
  DataListItemCard,
} from "./CardEvoElements";

/**
 *
 * Da clic en la card y ejecuta una funcion en context
 * que asigna una evolution en el context para que muestre el formEvolution
 * para editar o consultar datos de esa evo
 *
 */

const CardEvo = ({ evolution, showFormToUpdate }) => {
  const {
    evolution: { selEvolution },
  } = useUser();

  return (
    <CardEvoContainer
      onClick={() => {
        showFormToUpdate();
        selEvolution(evolution);
      }}
    >
      <CardHeader>{evolution.registedAt}</CardHeader>
      <CardBody>
        <GroupDataCard>
          <LabelCard>Motivo</LabelCard>
          <DataCard>{evolution.motive}</DataCard>
        </GroupDataCard>
        <GroupDataCard>
          <LabelCard>Diagnóstico</LabelCard>
          <DataListCard>
            {evolution.diagnosis.length > 0 &&
              evolution.diagnosis.map((diag, i) => (
                <DataListItemCard key={i}>
                  {diag.d} -{" "}
                  {diag.t === undefined
                    ? "Definitivo"
                    : diag.t
                    ? "Definitivo"
                    : "Presuntivo"}
                </DataListItemCard>
              ))}
            <DataCard>{evolution.notesdiag}</DataCard>
          </DataListCard>
        </GroupDataCard>
        <GroupDataCard>
          <LabelCard>Tratamiento</LabelCard>
          <DataCard>
            <b>Medicamentos: </b>
            {evolution.tratamiento.medicamentos || "No refiere"}
          </DataCard>
          <DataCard>
            <b>Indicaciones: </b>
            {evolution.tratamiento.indicaciones || "No refiere"}
          </DataCard>
        </GroupDataCard>
      </CardBody>
    </CardEvoContainer>
  );
};

export default CardEvo;
