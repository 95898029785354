import { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";

import {
  LoginContainer,
  LoginForm,
  H1Form,
  FormGroup,
  InputForm,
  LabelForm,
  LinkSmallForm,
  BtnLoginForm,
} from "./LoginElements";
import ShortLoading from "../../components/Loading/Short";

import useUser from "../../hooks/useUser";

const Home = () => {
  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    inputFocus.current.focus();
  }, []);

  const [values, setValues] = useState(initialValues);

  const inputFocus = useRef(null);

  const { login, loading, auth } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = values;
    await login(loginData);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  return (
    <>
      {loading && <ShortLoading />}
      {auth && <Redirect to="/" />}
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit}>
          <H1Form>Inicia Sesión</H1Form>
          <FormGroup>
            <LabelForm>Usuario</LabelForm>
            <InputForm
              ref={inputFocus}
              type="email"
              placeholder="Inserta tu email"
              required
              autoComplete="off"
              onChange={handleChange}
              name="email"
              value={values.email}
            />
          </FormGroup>
          <FormGroup>
            <LabelForm>Contraseña</LabelForm>
            <InputForm
              type="password"
              placeholder="Inserta tu password"
              required
              autoComplete="off"
              onChange={handleChange}
              name="password"
              value={values.password}
            />
          </FormGroup>
          <FormGroup textAlign="true">
            <LinkSmallForm to="/recoverpass">
              Olvidaste tu contraseña
            </LinkSmallForm>
          </FormGroup>
          <FormGroup textAlign="true">
            <BtnLoginForm type="submit">Iniciar</BtnLoginForm>
          </FormGroup>
        </LoginForm>
      </LoginContainer>
    </>
  );
};

export default Home;
