import styled from "styled-components";

export const EvolucionesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
`;

export const MenuEvoluciones = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const EvolucionesContent = styled.div`
  width: 100%;
`;
