import styled from "styled-components";
import { Link } from "react-router-dom";

export const FormHistoryContainer = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FormTitleArea = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0;
  text-align: left;
`;

export const FormGroupRow = styled.div`
  margin: 5px 0;
  text-align: left;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;

  @media screen and (max-width: 480px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

export const FormGroup = styled.div`
  /* margin: 5px 0; */
  text-align: left;
  padding: 5px;
  display: flex;
  width: "100%";
  flex-direction: column;
`;

export const LabelForm = styled.label`
  font-size: small;
  color: gray;
`;

export const TextAreaForm = styled.textarea`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
`;

export const FormGroupRow3 = styled.div`
  margin: 5px 0;
  text-align: left;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;

  @media screen and (max-width: 480px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

export const BtnActionsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

export const HrSeparador = styled.hr`
  border: 1px solid #eae5e5;
  margin: 10px 0;
`;

export const BtnGuardar = styled.button`
  cursor: pointer;
  text-decoration: none;
  width: 220px;
  padding: 10px 20px;
  font-family: inherit;
  font-size: 14px;
  text-align: center;
  background: #5ac8fa;
  border-radius: 10px;
  border: none;
  /* box-shadow: 5px 7px 11px -6px #000000; */
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
`;

export const BtnCancelar = styled(Link)`
  margin-left: 20px;
  cursor: pointer;
  width: 220px;
  background: #eee;
  text-decoration: none;
  padding: 10px 20px;
  color: #757575;
  border: none;
  border-radius: 10px;
  /* box-shadow: 5px 7px 11px -6px #000000; */
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
`;
