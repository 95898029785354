import styled from "styled-components";
import { FaPlus } from "react-icons/fa";

export const BtnAddContainer = styled.button`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 10px 10px;

  background: #ff8086;
  color: #fff;

  &:hover {
    background: #ff3b45;
  }

  @media screen and (max-width: 480px) {
    width: 35px;
    height: 30px;
    font-size: 0.8rem;
  }
`;

export const IconAdd = styled(FaPlus)``;
