import { useState } from "react";
import useUser from "../../hooks/useUser";
import {
  InputForm,
  ItemResult,
  ListResults,
  ResultContainer,
} from "./BuscadorPacientesElements";

const BuscadorPacientes = ({
  patients,
  query,
  handleChange,
  setClickQuery,
}) => {
  const {
    patient: { selPatient },
  } = useUser();

  const [result, setResult] = useState([]);

  const handleResult = (e) => {
    const res = patients.filter((patient) => {
      if (query === "") {
        return null;
      }

      return patient.name.toLowerCase().includes(query.toLowerCase());
    });
    setResult(res);
  };

  const ResultSearch = () => {
    return (
      <ResultContainer>
        <ListResults>
          {result.map((patient, id) => (
            <ItemResult
              key={id}
              onClick={() => {
                selPatient(patient);
                setClickQuery(patient.name);
                setResult([]);
              }}
            >
              {patient.name}
            </ItemResult>
          ))}
        </ListResults>
      </ResultContainer>
    );
  };

  return (
    <>
      <InputForm
        type="text"
        onChange={(e) => {
          handleChange(e);
          handleResult(e);
        }}
        value={query}
        name="query"
        autoComplete="off"
        placeholder="Inserta un apellido o un nombre y selecciona"
      />
      {query !== "" && <ResultSearch />}
      {/* <ResultSearch /> */}
    </>
  );
};

export default BuscadorPacientes;
