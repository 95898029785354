import styled from "styled-components";

export const MainContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background: #fcf2ff; */

  background: #fffcdc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #fffcdc,
    #ffffff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #fffcdc,
    #ffffff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 30%;
  width: 100%;
  padding: 15px;
  font-size: 2rem;
  font-weight: 100;

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const CardDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  padding-top: 15px;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const CardDataPrincipal = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 15px;
  align-items: center;

  width: 400px;
  height: 210px;
  margin: 10px;

  border: none;
  border-radius: 15px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: #dbf1f1;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: 200px;
  }
`;

export const CardDataPrincipalData = styled.div``;
export const CardDataPrincipalDetail = styled.div`
  display: flex;
  justify-content: center;
  text-align: right;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    align-items: center;
  }
`;

export const CardData = styled.div`
  display: grid;
  grid-template-rows: 65% 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 210px;
  margin: 10px;

  border: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  padding: 15px;

  background: ${({ colorCard }) => (colorCard ? colorCard : "salmon")};
`;

export const CardDataVariable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  font-weight: 500;
  height: 100%;
  overflow: hidden;
`;

export const CardDataTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: center;
`;

export const CardDataDetail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`;
