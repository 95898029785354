import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  FormHistoryContainer,
  FormTitleArea,
  FormGroupRow,
  FormGroup,
  LabelForm,
  TextAreaForm,
  FormGroupRow3,
  BtnActionsArea,
  BtnGuardar,
  BtnCancelar,
} from "./FormHistoryElements";
import useUser from "../../hooks/useUser";
import { calcEdad } from "../../lib/utilies";

const FormHistory = ({ history }) => {
  const historyRRD = useHistory();
  const {
    user: { id, cm },
    patient: { patient, selPatient },
    historia: { addHistory, updateHistory, selHistory },
    evolution: { selEvolution },
  } = useUser();

  const initialValues = {
    id_cm: cm,
    id_patient: "",
    antecprenatales: "",
    antecneopost: "",
    antecperpatologicos: "",
    antecpatfamiliares: "",
    medicamentos: "",
    transfusiones: "",
    alergias: "",
    id_user: id,
  };

  const [historia, setHistoria] = useState(initialValues);
  const [edad, setEdad] = useState("0");

  const handleChange = (e) => {
    const value = e.target.value;
    setHistoria({
      ...historia,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (history?._id !== undefined) {
      setHistoria({
        ...historia,
        id_patient: patient._id,
        antecprenatales: history.antecprenatales,
        antecneopost: history.antecneopost,
        antecperpatologicos: history.antecperpatologicos,
        antecpatfamiliares: history.antecpatfamiliares,
        medicamentos: history.medicamentos || "No refiere",
        transfusiones: history.transfusiones || "No refiere",
        alergias: history.alergias || "No refiere",
      });
    }

    if (patient?._id !== undefined) {
      const age = calcEdad(patient.nacimiento);
      setEdad(age.num);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();

    let data = {
      id_patient: patient._id,
      antecperpatologicos: historia.antecperpatologicos,
      antecpatfamiliares: historia.antecpatfamiliares,
      medicamentos: historia.medicamentos,
      transfusiones: historia.transfusiones,
      alergias: historia.alergias,
      id_user: id,
      id_cm: cm,
    };

    if (patient.ped) {
      data = {
        ...data,
        antecprenatales: historia.antecprenatales,
        antecneopost: historia.antecneopost,
      };
    }

    if (history._id !== undefined) {
      await updateHistory(data, history._id);

      selPatient([]);
      selHistory([]);
      selEvolution([]);
    } else {
      await addHistory(data);

      historyRRD.push("/evoluciones");
    }
  };

  return (
    <FormHistoryContainer onSubmit={handleSave}>
      <FormTitleArea>Antecedentes</FormTitleArea>
      <FormGroupRow>
        {edad <= 14 && (
          <>
            <FormGroup>
              <LabelForm>Prenatales</LabelForm>
              <TextAreaForm
                rows="3"
                placeholder="Inserte antecedentes prenatales"
                onChange={handleChange}
                value={historia.antecprenatales}
                name="antecprenatales"
              />
            </FormGroup>
            <FormGroup>
              <LabelForm>Neonatales y Postnatales</LabelForm>
              <TextAreaForm
                rows="3"
                placeholder="Inserte antecedentes Neonatales y Postnatales"
                onChange={handleChange}
                value={historia.antecneopost}
                name="antecneopost"
              />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <LabelForm>Personales Patológicos</LabelForm>
          <TextAreaForm
            rows="3"
            placeholder="Inserte antecedentes Personales Patológicos"
            onChange={handleChange}
            value={historia.antecperpatologicos}
            name="antecperpatologicos"
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Patológicos Familiares</LabelForm>
          <TextAreaForm
            rows="3"
            placeholder="Inserte antecedentes Patológicos Familiares"
            onChange={handleChange}
            value={historia.antecpatfamiliares}
            name="antecpatfamiliares"
          />
        </FormGroup>
      </FormGroupRow>
      <FormGroupRow3>
        <FormGroup>
          <LabelForm>Medicamentos</LabelForm>
          <TextAreaForm
            rows="5"
            placeholder="Inserte medicamentes que toma el paciente actualmente"
            onChange={handleChange}
            value={historia.medicamentos}
            name="medicamentos"
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Alergias</LabelForm>
          <TextAreaForm
            rows="5"
            placeholder="Inserte alergias que tenga el paciente actualmente"
            onChange={handleChange}
            value={historia.alergias}
            name="alergias"
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Transfusiones</LabelForm>
          <TextAreaForm
            rows="5"
            placeholder="Inserte transfusiones que haya tenido el paciente"
            onChange={handleChange}
            value={historia.transfusiones}
            name="transfusiones"
          />
        </FormGroup>
      </FormGroupRow3>
      <BtnActionsArea>
        <BtnGuardar type="submit">
          {history?._id === undefined ? "Guardar" : "Actualizar"}
        </BtnGuardar>
        <BtnCancelar
          to="/"
          onClick={() => {
            selPatient([]);
            selHistory([]);
            selEvolution([]);
          }}
        >
          Cancelar
        </BtnCancelar>
      </BtnActionsArea>
    </FormHistoryContainer>
  );
};

export default FormHistory;
