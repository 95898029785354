import styled from "styled-components";

export const PatientContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
  overflow-y: auto;

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const MenuPatient = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
`;

export const PatientContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
`;
