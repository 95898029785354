import styled from "styled-components";

export const ProfileBarContainer = styled.div`
  position: fixed;
  z-index: 1;
  width: 26%;
  /* height: 50vh; */
  background: #eae8e8;
  align-items: center;
  top: 59px;
  right: 0;
  transition: 0.5s ease-in-out;
  opacity: ${({ isOpenP }) => (isOpenP ? "100%" : "0")};
  top: ${({ isOpenP }) => (isOpenP ? "59px" : "-100%")};
  padding: 20px 20px;
  overflow: hidden;
  border-radius: 0 0 10px 10px;

  @media screen and (max-width: 480px) {
    width: 100%;
    /* height: 50vh; */
  }
`;

export const ProfileInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #455a64;
  text-align: center;
  font-size: 16px;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const MedicImgWrap = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  margin-bottom: 20px;
`;

export const MedicImg = styled.img`
  max-width: 80px;
`;

export const BtnActionWrap = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    margin-top: 15px;
  }
`;

export const BtnActionLogout = styled.button`
  border-radius: 15px;
  width: 80%;
  background: #f33;
  color: #fff;
  border: none;
  font-size: 1rem;
  padding: 10px 6px;

  &:hover {
    background: #e60909;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
    padding: 15px 20px;
    font-size: 18px;
  }
`;

export const TextProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InfoNombreUsuario = styled.p`
  font-size: 18px;
  font-weight: 600;
`;
export const InfoDetailProfile = styled.p`
  font-size: small;
`;
