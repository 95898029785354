import moment from "moment";

export const calcEdad = (nacimiento) => {
  const fecha = new Date(nacimiento);
  const hoy = new Date();

  if (fecha < hoy) {
    const now = moment();
    const fnaci = moment(nacimiento);

    let anios = now.diff(fnaci, "years");
    let meses = now.diff(fnaci, "months");
    let dias = now.diff(fnaci, "days");

    if (anios === 0) {
      if (meses === 0) {
        if (dias > 1) {
          return {
            num: 0,
            edad: `${dias} días`,
          };
        } else if (dias === 1) {
          return {
            num: 0,
            edad: `${dias} día`,
          };
        }
      } else if (meses > 0) {
        return {
          num: 0,
          edad: `${meses} meses`,
        };
      } else if (meses === 1) {
        return {
          num: 0,
          edad: `${meses} mes`,
        };
      }
    } else if (anios > 0) {
      let m = meses - 12 * anios;
      const years = anios > 1 ? `${anios} años` : `${anios} año`;
      const months = m > 1 ? `${m} meses` : m === 1 ? `${m} mes` : ``;

      return {
        edad: months === 0 ? `${years}` : `${years} ${months}`,
        num: anios,
      };
    }
  } else return false;
};
