import styled from "styled-components";
import { FaTrashAlt, FaPen } from "react-icons/fa";

export const ListCitasContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

export const ListHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 20px;
  color: #332c2c;
  font-weight: 700;
  letter-spacing: 2px;
  border: none;
  border-radius: 10px 10px 0 0;

  background: #9eddf5a3; /* fallback for old browsers */
`;

export const ListCitasBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

export const ListCitasList = styled.ul``;

export const ListCitasItems = styled.li`
  list-style: none;
  padding: 15px;
  box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 5px 1px #91ffff;
  }
`;

export const ListDetailSubject = styled.span`
  font-size: 12px;
`;

export const ListDetailDate = styled.span`
  font-size: 10px;
`;

export const ListDetailNote = styled.div`
  font-size: 10px;
`;

export const ListNull = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  padding: 20px;
  text-align: center;
`;

export const ListItemHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const BtnListItemCitas = styled.div`
  font-size: 14px;
`;

export const BtnItem = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const IconEdit = styled(FaPen)`
  color: green;
  margin-right: 10px;
`;
export const IconDel = styled(FaTrashAlt)`
  color: #ff0000;
`;
