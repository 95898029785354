import { Redirect } from "react-router-dom";
import Certificados from "../../components/Certificados";
import useUser from "../../hooks/useUser";

const PCertificados = () => {
  const {
    evolution: { evolution },
  } = useUser();

  if (evolution._id !== undefined) {
    return <Certificados />;
  } else {
    return <Redirect to="/evoluciones" />;
  }
};

export default PCertificados;
