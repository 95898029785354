import { useEffect, useState } from "react";

import {
  PatientContainer,
  MenuPatient,
  PatientContent,
} from "./PatientsElements.js";
import useUser from "../../hooks/useUser.js";

import Buscador from "../../components/Buscador";
import FormPatient from "../../components/FormPatient";
import BtnActions from "../../components/BtnActions";
import BtnAdd from "../../components/BtnAdd";

const Pacientes = () => {
  const {
    user: { cm },
    patient: { patient },
    token,
  } = useUser();

  const [patients, setPatients] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const URIROOT = process.env.REACT_APP_URIROOT;

  const fetchPatients = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };
    const res = await fetch(`${URIROOT}/patient/all/${cm}`, config);
    const data = await res.json();

    setPatients(data.data);
  };

  useEffect(() => {
    fetchPatients();
    if (patient._id !== undefined) {
      setIsAdd(true);
    } else if (patient._id === undefined) {
      setIsAdd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsAdd = () => {
    setIsAdd(true);
  };

  const handleNoIsAdd = () => {
    setIsAdd(false);
  };

  return (
    <PatientContainer>
      <MenuPatient>
        <Buscador
          patients={patients}
          formShow={handleIsAdd}
          formNoShow={handleNoIsAdd}
          show={isAdd}
        />
        <BtnAdd showForm={() => setIsAdd(!isAdd)} />
      </MenuPatient>
      <BtnActions />
      {isAdd && (
        <PatientContent>
          <FormPatient patient={patient} />
        </PatientContent>
      )}
    </PatientContainer>
  );
};

export default Pacientes;
