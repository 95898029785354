import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import useUser from "../../hooks/useUser";
import {
  FormGroup,
  FormRecetaContainer,
  FormRecetaForm,
  LabelForm,
  InputForm,
  SelectFieldForm,
  OptionSelectField,
  FormTitleArea,
  TextAreaForm,
  LabelSearchArea,
  InputSearchArea,
  ResultsAreaSearch,
  ResultItemSearch,
  AreaLisForm,
  ListFormItem,
  CheckAreaForm,
  CheckInputForm,
  BtnActionsArea,
  BtnGuardar,
  BtnCancelar,
} from "./FormRecetaElements";
import cie10 from "../../lib/cie10.json";
import { SearchArea } from "../Buscador/BuscadorElements";
import { calcEdad } from "../../lib/utilies";

const FormReceta = ({ doctores, dcm }) => {
  const {
    patient: { patient },
    presc: { addPresc, printPresc },
    user: { id, cm },
  } = useUser();

  const initialForm = {
    medicamentos: "",
    indicaciones: "",
    id_patient: "",
    id_doctor: "",
    id_cm: cm,
    id_user: id,
    diagnosis: [],
  };

  const [form, setForm] = useState(initialForm);
  const [searchDiag, setSearchDiag] = useState("");
  const [fdiags, setFDiags] = useState([]);

  const handleChange = (e) => {
    const value = e.target.value;
    const { name } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleChangeFinders = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "searchDiag") {
      setSearchDiag(value);
      SearchDiagnosis(value);
    }
  };

  const SearchDiagnosis = (query) => {
    const diags = cie10.filter(
      (diag) =>
        diag.c.toLowerCase().includes(query.toLowerCase()) ||
        diag.d.toLowerCase().includes(query.toLowerCase())
    );

    if (query === "") {
      setFDiags([]);
    } else {
      setFDiags(diags);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.id_doctor === "") {
      toast("Selecciona un doctor", {
        autoClose: 2000,
        type: "warning",
      });
    } else {
      const idpresc = `PRE${patient.CI}${String(moment().format("DDMMYYYY"))}`;
      const age = await calcEdad(patient.nacimiento).edad;
      const tipo = patient.ped ? "ped" : "adul";
      const fecha = `${moment().format("DD")} de ${moment().format(
        "MMMM"
      )} del ${moment().format("YYYY")}`;
      const doctor = doctores.filter(
        (doctor) => doctor._id === form.id_doctor
      )[0];

      const data = {
        medicamentos: form.medicamentos,
        indicaciones: form.indicaciones,
        diagnosis: form.diagnosis,
        notasDiag: form.notasDiag,
        id_patient: patient._id,
        id_doctor: form.id_doctor,
        id_cm: cm,
        id_user: id,
      };

      const dataPdf = {
        tipo,
        cmname: dcm.name,
        drname: doctor.name,
        dresp: doctor.especialidad,
        drphone: doctor.phone,
        dremail: doctor.email,
        cmaddress: dcm.address,
        cmcity: dcm.city,
        cmcountry: dcm.country,
        codcm: dcm.codigo,
        idpresc,
        name: patient.name,
        ci: patient.CI,
        fecha,
        age,
        medicamentos: form.medicamentos,
        indicaciones: form.indicaciones,
        diagnostico: form.diagnosis,
        notasDiag: form.notasDiag,
      };

      await addPresc(data);
      await printPresc(dataPdf);
    }
  };

  return (
    <FormRecetaContainer>
      <FormRecetaForm onSubmit={handleSubmit}>
        <FormGroup>
          <LabelForm>Nombre Paciente</LabelForm>
          <InputForm
            type="text"
            name="name"
            placeholder="Nombre del paciente"
            value={patient.name}
            onChange={handleChange}
            readOnly
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Doctor</LabelForm>
          <SelectFieldForm
            name="id_doctor"
            value={form.id_doctor}
            onChange={handleChange}
          >
            <OptionSelectField value="null">
              Seleccione doctor
            </OptionSelectField>
            {doctores.map((doctor, id) => (
              <OptionSelectField key={id} value={doctor._id}>
                {doctor.name}
              </OptionSelectField>
            ))}
          </SelectFieldForm>
        </FormGroup>
        <FormGroup>
          <LabelForm>Diagnóstico CIE10</LabelForm>
          <SearchArea>
            <LabelSearchArea>🔍</LabelSearchArea>
            <InputSearchArea
              type="text"
              placeholder="Inserta código o diagnóstico"
              value={searchDiag}
              name="searchDiag"
              onChange={handleChangeFinders}
            />
          </SearchArea>
          {fdiags.length > 0 && (
            <ResultsAreaSearch>
              {fdiags.map((diag, i) => (
                <ResultItemSearch
                  key={i}
                  onClick={() => {
                    const d = form.diagnosis.filter((x) => x.c === diag.c);
                    if (d.length === 0) {
                      setForm({
                        ...form,
                        diagnosis: [
                          ...form.diagnosis,
                          { c: diag.c, d: diag.d, t: false },
                        ],
                      });
                    }
                    setSearchDiag("");
                    setFDiags([]);
                  }}
                >
                  {diag.c}-{diag.d}
                </ResultItemSearch>
              ))}
            </ResultsAreaSearch>
          )}
          {form.diagnosis.length > 0 && (
            <AreaLisForm>
              {form.diagnosis.map((diag, i) => (
                <ListFormItem key={i}>
                  <div
                    onClick={() => {
                      const d = form.diagnosis.filter((x) => x.c !== diag.c);
                      setForm({
                        ...form,
                        diagnosis: d,
                      });
                    }}
                  >
                    {diag.c}-{diag.d}
                  </div>
                  <CheckAreaForm>
                    <LabelForm>
                      Definitivo
                      <CheckInputForm
                        checked={diag.t}
                        type="checkbox"
                        onChange={(e) => {
                          const d = form.diagnosis.filter(
                            (x) => x.c !== diag.c
                          );
                          setForm({
                            ...form,
                            diagnosis: [
                              ...d,
                              { c: diag.c, d: diag.d, t: !diag.t },
                            ],
                          });
                        }}
                      />
                    </LabelForm>
                  </CheckAreaForm>
                </ListFormItem>
              ))}
            </AreaLisForm>
          )}
        </FormGroup>
        <FormGroup>
          <LabelForm>Notas de Diagnóstico</LabelForm>
          <TextAreaForm
            placeholder="Notas de diagnóstico"
            rows="2"
            value={form.notasDiag}
            onChange={handleChange}
            name="notasDiag"
          />
        </FormGroup>
        <FormGroup>
          <FormTitleArea>Tratamiento</FormTitleArea>
          <LabelForm>Medicamentos</LabelForm>
          <TextAreaForm
            placeholder="Medicamentos del tratamiento"
            rows="3"
            value={form.medicamentos}
            onChange={handleChange}
            name="medicamentos"
            required
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Indicamentos</LabelForm>
          <TextAreaForm
            placeholder="Indicaciones del tratamiento"
            rows="3"
            value={form.indicaciones}
            onChange={handleChange}
            name="indicaciones"
            required
          />
        </FormGroup>
        <BtnActionsArea>
          <BtnGuardar type="submit">Guardar</BtnGuardar>
          <BtnCancelar to="/">Cancelar</BtnCancelar>
        </BtnActionsArea>
      </FormRecetaForm>
    </FormRecetaContainer>
  );
};

export default FormReceta;
