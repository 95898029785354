import styled from "styled-components";
import { Link } from "react-router-dom";

export const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  /* background: #fcf2ff; */
  overflow: hidden;

  background: #fffcdc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #fffcdc,
    #ffffff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #fffcdc,
    #ffffff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const LoginForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  margin-top: -240px;
  padding: 40px;
  /* box-shadow: 5px 7px 11px -6px #000000; */
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: #e8e8e8;

  &:h1 {
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    width: 90%;
    margin-top: -280px;
  }
`;

export const H1Form = styled.h1`
  font-size: 25px;
`;

export const FormGroup = styled.div`
  text-align: ${({ textAlign }) => (!textAlign ? "left" : "right")};
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LabelForm = styled.label`
  font-size: small;
  color: gray;
`;

export const InputForm = styled.input`
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
  border: none;
  font-family: inherit;
  outline: none;
`;

export const LinkSmallForm = styled(Link)`
  font-size: small;
  color: gray;
  text-decoration: none;
  cursor: pointer;
`;

export const BtnLoginForm = styled.button`
  border-radius: 5px;
  width: 100%;
  padding: 10px 10px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  color: #ffff;
  letter-spacing: 2px;
  font-family: inherit;

  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  &:hover {
    background: #d45efb;
  }
`;
