import { useState } from "react";
import { BtnMoreContainer, IconMore, IconLess } from "./BtnMoreElements";

const BtnMore = ({ showInfo }) => {
  const [changeIcon, setChangeIcon] = useState(false);

  return (
    <BtnMoreContainer
      onClick={() => {
        showInfo();
        setChangeIcon(!changeIcon);
      }}
    >
      {!changeIcon ? <IconMore /> : <IconLess />}
    </BtnMoreContainer>
  );
};

export default BtnMore;
