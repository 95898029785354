import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";

import useUser from "../../hooks/useUser";
import {
  FormEvolutionContainer,
  FormTitleArea,
  FormGroup,
  FormGroup2,
  LabelForm,
  TextAreaForm,
  FormGrid2Col,
  FormGrid3Col,
  SelectFieldForm,
  OptionSelectField,
  InputForm,
  BtnActionsArea,
  AreaLisForm,
  ListFormItem,
  BtnGuardar,
  BtnCancelar,
  SearchArea,
  LabelSearchArea,
  InputSearchArea,
  ResultsAreaSearch,
  ResultItemSearch,
  CheckAreaForm,
  CheckInputForm,
} from "./FormEvolutionElements";

import cie10 from "../../lib/cie10.json";
import exlabs from "../../lib/exlab.json";

const FormEvolution = () => {
  const URIROOT = process.env.REACT_APP_URIROOT;

  const selRef = useRef(null);
  const [doctores, setDoctores] = useState([]);
  const [searchExLab, setSearchExLab] = useState("");
  const [searchDiag, setSearchDiag] = useState("");
  const [fdiags, setFDiags] = useState([]);
  const [fexams, setFExams] = useState([]);
  const {
    patient: { patient, selPatient },
    historia: { selHistory },
    evolution: { evolution, addEvolution, updateEvolution, selEvolution },
    user: { cm, id },
    token,
  } = useUser();

  useEffect(() => {
    fetchDoctores();

    if (evolution._id !== undefined) {
      setFEvolution({
        ...fevolution,
        id_doctor: evolution.id_doctor,
        motive: evolution.motive,
        enfermactual: evolution.enfermactual,
        peso: evolution.peso,
        talla: evolution.talla,
        imc: evolution.imc,
        pcefalico: evolution.pcefalico,
        temperatura: evolution.temperatura,
        fcardiaca: evolution.fcardiaca,
        frespiratoria: evolution.frespiratoria,
        saturacion: evolution.saturacion,
        tarterial: evolution.tarterial,
        exfisic: evolution.exfisic,
        resexlab: evolution.resexlab,
        diagnosis: evolution.diagnosis,
        notesdiag: evolution.notesdiag,
        exams: evolution.exams,
        notesexlab: evolution.notesexlab,
        tratamiento: {
          medicamentos: evolution.tratamiento.medicamentos,
          indicaciones: evolution.tratamiento.indicaciones,
        },
        id_user: evolution.id_user,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    id_patient: patient._id,
    id_cm: cm,
    id_doctor: "",
    motive: "",
    enfermactual: "",
    peso: 0,
    talla: 0,
    imc: 0,
    pcefalico: 0,
    temperatura: 0,
    fcardiaca: 0,
    frespiratoria: 0,
    saturacion: 0,
    tarterial: 0,
    exfisic: "",
    resexlab: "",
    diagnosis: [],
    notesdiag: "",
    exams: [],
    notesexlab: "",
    tratamiento: {
      medicamentos: "",
      indicaciones: "",
    },
    id_user: id,
  };
  const [fevolution, setFEvolution] = useState(initialValues);

  const handleChangeReceta = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFEvolution({
      ...fevolution,
      tratamiento: {
        ...fevolution.tratamiento,
        [name]: value,
      },
    });
  };

  const SearchExLab = (query) => {
    const exams = exlabs.filter(
      (exam) =>
        exam.grupo.toLowerCase().includes(query.toLowerCase()) ||
        exam.examen.toLowerCase().includes(query.toLowerCase())
    );

    if (query === "") {
      setFExams([]);
    } else {
      setFExams(exams);
    }
  };

  const SearchDiagnosis = (query) => {
    const diags = cie10.filter(
      (diag) =>
        diag.c.toLowerCase().includes(query.toLowerCase()) ||
        diag.d.toLowerCase().includes(query.toLowerCase())
    );

    if (query === "") {
      setFDiags([]);
    } else {
      setFDiags(diags);
    }
  };

  const handleChangeFinders = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "searchExLab") {
      setSearchExLab(value);
      SearchExLab(value);
    } else if (name === "searchDiag") {
      setSearchDiag(value);
      SearchDiagnosis(value);
    }
  };

  const fetchDoctores = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/doctor/all/${cm}`, config);
    const data = await res.json();

    setDoctores(data.data);
  };

  const handleChange = (e) => {
    const value = e.target.value;

    setFEvolution({
      ...fevolution,
      [e.target.name]: value,
    });
  };

  const handleChangeIMC = (e) => {
    if (e.target.name === "peso") {
      setFEvolution({
        ...fevolution,
        peso: e.target.value,
        imc:
          fevolution.talla > 0
            ? Number(e.target.value) / (Number(fevolution.talla) / 100) ** 2
            : 0,
      });
    } else if (e.target.name === "talla") {
      setFEvolution({
        ...fevolution,
        talla: e.target.value,
        imc:
          Number(e.target.value) > 0
            ? Number(fevolution.peso) / (Number(e.target.value) / 100) ** 2
            : 0,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      fevolution.id_doctor === "selecciona doctor" ||
      fevolution.id_doctor === ""
    ) {
      toast("Debes seleccionar un doctor para esta evolución", {
        autoClose: 2000,
        type: "warning",
      });
      selRef.current.focus();
    } else {
      const data = {
        id_patient: fevolution.id_patient,
        id_cm: fevolution.id_cm,
        id_doctor: fevolution.id_doctor,
        motive: fevolution.motive,
        enfermactual:
          fevolution.enfermactual === ""
            ? "No refiere"
            : fevolution.enfermactual,
        peso: fevolution.peso,
        talla: fevolution.talla,
        imc: fevolution.imc,
        pcefalico: fevolution.pcefalico,
        temperatura: fevolution.temperatura,
        fcardiaca: fevolution.fcardiaca,
        frespiratoria: fevolution.frespiratoria,
        saturacion: fevolution.saturacion,
        tarterial: fevolution.tarterial,
        exfisic: fevolution.exfisic === "" ? "No refiere" : fevolution.exfisic,
        resexlab:
          fevolution.resexlab === "" ? "No refiere" : fevolution.resexlab,
        diagnosis: fevolution.diagnosis,
        notesdiag:
          fevolution.notesdiag === "" ? "No refiere" : fevolution.notesdiag,
        exams: fevolution.exams,
        notesexlab:
          fevolution.notesexlab === "" ? "No refiere" : fevolution.notesexlab,
        tratamiento: {
          medicamentos:
            fevolution.tratamiento.medicamentos === ""
              ? "No refiere"
              : fevolution.tratamiento.medicamentos,
          indicaciones:
            fevolution.tratamiento.indicaciones === ""
              ? "No refiere"
              : fevolution.tratamiento.indicaciones,
        },
        id_user: fevolution.id_user,
      };

      if (evolution._id !== undefined) {
        await updateEvolution(data, evolution._id);

        selPatient([]);
        selHistory([]);
        selEvolution([]);
      } else {
        await addEvolution(data);
      }
    }
  };

  return (
    <FormEvolutionContainer onSubmit={handleSubmit}>
      <FormGrid2Col>
        <FormGroup>
          <LabelForm>Médico/a</LabelForm>

          <SelectFieldForm
            value={fevolution.id_doctor}
            name="id_doctor"
            onChange={handleChange}
            ref={selRef}
          >
            <OptionSelectField value="selecciona doctor">
              Selecciona doctor
            </OptionSelectField>
            {doctores.map((dr) => (
              <OptionSelectField key={dr._id} value={dr._id}>
                {dr.name}
              </OptionSelectField>
            ))}
          </SelectFieldForm>
        </FormGroup>
        <FormGroup2>
          <LabelForm>
            <b>Hora de atención</b>
          </LabelForm>
          <LabelForm>{moment().format("dddd, DD MMMM, YYYY h:mm a")}</LabelForm>
        </FormGroup2>
      </FormGrid2Col>
      <FormGrid2Col>
        <FormGroup>
          <LabelForm>Motivo</LabelForm>
          <TextAreaForm
            placeholder="Inserta el motivo de la consulta"
            value={fevolution.motive}
            name="motive"
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Enfermedad Actual</LabelForm>
          <TextAreaForm
            placeholder="Inserta enfermedad actual del paciente"
            value={fevolution.enfermactual}
            name="enfermactual"
            onChange={handleChange}
          />
        </FormGroup>
      </FormGrid2Col>
      <FormGrid3Col>
        <FormGroup>
          <LabelForm>Peso (kg)</LabelForm>
          <InputForm
            type="number"
            placeholder="0"
            value={fevolution.peso}
            name="peso"
            onChange={handleChangeIMC}
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>
            Talla (cm<sup>2</sup>)
          </LabelForm>
          <InputForm
            type="number"
            placeholder="0"
            value={fevolution.talla}
            name="talla"
            onChange={handleChangeIMC}
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>
            IMC (kilos/cm<sup>2</sup>)
          </LabelForm>
          <InputForm
            tyoe="number"
            placeholder="0"
            value={Number(fevolution.imc).toFixed(2)}
            name="imc"
            readOnly
          />
        </FormGroup>
        {patient.ped && (
          <FormGroup>
            <LabelForm>Perímetro Cefálico (cm)</LabelForm>
            <InputForm
              type="number"
              placeholder="0"
              value={fevolution.pcefalico}
              name="pcefalico"
              onChange={handleChange}
            />
          </FormGroup>
        )}
        <FormGroup>
          <LabelForm>Temperatura (centigrados)</LabelForm>
          <InputForm
            type="number"
            placeholder="0"
            value={fevolution.temperatura}
            name="temperatura"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Frecuencia Cardiaca (lpm)</LabelForm>
          <InputForm
            tyoe="number"
            placeholder="0"
            value={fevolution.fcardiaca}
            name="fcardiaca"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Frecuencia Respiratoria (rpm)</LabelForm>
          <InputForm
            type="number"
            placeholder="0"
            value={fevolution.frespiratoria}
            name="frespiratoria"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Saturación (%)</LabelForm>
          <InputForm
            type="number"
            placeholder="0"
            value={fevolution.saturacion}
            name="saturacion"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Tensión Arterial (mmHg)</LabelForm>
          <InputForm
            tyoe="number"
            placeholder="0"
            value={fevolution.tarterial}
            name="tarterial"
            onChange={handleChange}
          />
        </FormGroup>
      </FormGrid3Col>
      <FormGroup>
        <LabelForm>Exámen Físico</LabelForm>
        <TextAreaForm
          placeholder="Inserta examen físico"
          value={fevolution.exfisic}
          name="exfisic"
          onChange={handleChange}
        />
      </FormGroup>
      <FormGrid2Col>
        <FormGroup>
          <LabelForm>Diagnóstico CIE10</LabelForm>
          <SearchArea>
            <LabelSearchArea>🔍</LabelSearchArea>
            <InputSearchArea
              type="text"
              placeholder="Inserta código o diagnóstico"
              value={searchDiag}
              name="searchDiag"
              onChange={handleChangeFinders}
            />
          </SearchArea>
          {fdiags.length > 0 && (
            <ResultsAreaSearch>
              {fdiags.map((diag, i) => (
                <ResultItemSearch
                  key={i}
                  onClick={() => {
                    const d = fevolution.diagnosis.filter(
                      (x) => x.c === diag.c
                    );
                    if (d.length === 0) {
                      setFEvolution({
                        ...fevolution,
                        diagnosis: [
                          ...fevolution.diagnosis,
                          { c: diag.c, d: diag.d, t: false },
                        ],
                      });
                    }
                    setSearchDiag("");
                    setFDiags([]);
                  }}
                >
                  {diag.c}-{diag.d}
                </ResultItemSearch>
              ))}
            </ResultsAreaSearch>
          )}
          {fevolution.diagnosis.length > 0 && (
            <AreaLisForm>
              {fevolution.diagnosis.map((diag, i) => (
                <ListFormItem key={i}>
                  <div
                    onClick={() => {
                      const d = fevolution.diagnosis.filter(
                        (x) => x.c !== diag.c
                      );
                      setFEvolution({
                        ...fevolution,
                        diagnosis: d,
                      });
                    }}
                  >
                    {diag.c}-{diag.d}
                  </div>
                  <CheckAreaForm>
                    <LabelForm>
                      Definitivo
                      <CheckInputForm
                        checked={diag.t}
                        type="checkbox"
                        onChange={(e) => {
                          const d = fevolution.diagnosis.filter(
                            (x) => x.c !== diag.c
                          );
                          setFEvolution({
                            ...fevolution,
                            diagnosis: [
                              ...d,
                              { c: diag.c, d: diag.d, t: !diag.t },
                            ],
                          });
                        }}
                      />
                    </LabelForm>
                  </CheckAreaForm>
                </ListFormItem>
              ))}
            </AreaLisForm>
          )}
        </FormGroup>
        <FormGroup>
          <LabelForm>Notas del Diagnóstico</LabelForm>
          <TextAreaForm
            placeholder="Inserta notas de diagnóstico"
            value={fevolution.notesdiag}
            name="notesdiag"
            onChange={handleChange}
            rows="5"
          />
        </FormGroup>
      </FormGrid2Col>
      <br />
      <FormTitleArea>Exámenes de Laboratorio</FormTitleArea>
      <FormGrid3Col>
        <FormGroup>
          <LabelForm>Resultado de Exámenes</LabelForm>
          <TextAreaForm
            placeholder="Inserta resultado de examenes"
            value={fevolution.resexlab}
            name="resexlab"
            onChange={handleChange}
            rows="5"
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Pedido de Laboratorio</LabelForm>
          <SearchArea>
            <LabelSearchArea>🔍</LabelSearchArea>
            <InputSearchArea
              type="text"
              placeholder="Inserta nombre o grupo de exámen"
              value={searchExLab}
              name="searchExLab"
              onChange={handleChangeFinders}
            />
          </SearchArea>
          {fexams.length > 0 && (
            <ResultsAreaSearch>
              {fexams.map((exam, i) => (
                <ResultItemSearch
                  key={i}
                  onClick={() => {
                    setFEvolution({
                      ...fevolution,
                      exams: [...fevolution.exams, exam],
                    });
                    setSearchExLab("");
                    setFExams([]);
                  }}
                >
                  {exam.examen}
                </ResultItemSearch>
              ))}
            </ResultsAreaSearch>
          )}
          {fevolution.exams.length > 0 && (
            <AreaLisForm>
              {fevolution.exams.map((exam, i) => (
                <ListFormItem
                  key={i}
                  onClick={() => {
                    const e = fevolution.exams.filter(
                      (x) => x.examen !== exam.examen
                    );
                    setFEvolution({
                      ...fevolution,
                      exams: e,
                    });
                  }}
                >
                  {exam.examen}
                </ListFormItem>
              ))}
            </AreaLisForm>
          )}
        </FormGroup>
        <FormGroup>
          <LabelForm>Nota de pedido de exámen</LabelForm>
          <TextAreaForm
            placeholder="Inserta notas de pedido de exámen de laboratorio"
            value={fevolution.notesexlab}
            name="notesexlab"
            onChange={handleChange}
            rows="5"
          />
        </FormGroup>
      </FormGrid3Col>
      <br />
      <FormTitleArea>Tratamiento - Prescripción Médica - Receta</FormTitleArea>
      <FormGrid2Col>
        <FormGroup>
          <LabelForm>Medicamentos</LabelForm>
          <TextAreaForm
            placeholder="Inserta medicamentos del tratamiento"
            value={fevolution.tratamiento.medicamentos}
            name="medicamentos"
            onChange={handleChangeReceta}
            rows="5"
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Indicaciones</LabelForm>
          <TextAreaForm
            placeholder="Inserta indicaciones del tratamiento"
            value={fevolution.tratamiento.indicaciones}
            name="indicaciones"
            onChange={handleChangeReceta}
            rows="5"
          />
        </FormGroup>
      </FormGrid2Col>
      <BtnActionsArea>
        <BtnGuardar type="submit">
          {evolution._id !== undefined ? "Actualizar" : "Guardar"}
        </BtnGuardar>
        <BtnCancelar
          to="/"
          onClick={() => {
            selPatient([]);
            selHistory([]);
            selEvolution([]);
          }}
        >
          Cancelar
        </BtnCancelar>
      </BtnActionsArea>
    </FormEvolutionContainer>
  );
};

export default FormEvolution;
