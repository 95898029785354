import { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import MainContent from "../MainContent";
import ShortLoading from "../Loading/Short";
import Login from "../../pages/Login";
import Historias from "../../pages/Historias";
import Evoluciones from "../../pages/Evoluciones";
import Recetas from "../../pages/Recetas";
import Citas from "../../pages/Citas";
import Pacientes from "../../pages/Pacientes";
import Ajustes from "../../pages/Ajustes";
import Certificados from "../../pages/Certificados";

import useUser from "../../hooks/useUser";
import RecoverPass from "../../pages/Login/RecoverPass";

const Routes = () => {
  const { auth, loading, validateToken } = useUser();

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("userSession"));

    if (session) {
      validateToken(session.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <ShortLoading />
      ) : (
        <MainContent>
          <Switch>
            <Route exact path="/historias">
              {!auth ? <Redirect to="/signin" /> : <Historias />}
            </Route>
            <Route exact path="/evoluciones">
              {!auth ? <Redirect to="/signin" /> : <Evoluciones />}
            </Route>
            <Route exact path="/recetas">
              {!auth ? <Redirect to="/signin" /> : <Recetas />}
            </Route>
            <Route exact path="/citas">
              {!auth ? <Redirect to="/signin" /> : <Citas />}
            </Route>
            <Route exact path="/pacientes">
              {!auth ? <Redirect to="/signin" /> : <Pacientes />}
            </Route>
            <Route exact path="/ajustes">
              {!auth ? <Redirect to="/signin" /> : <Ajustes />}
            </Route>
            <Route exact path="/certificados">
              {!auth ? <Redirect to="/signin" /> : <Certificados />}
            </Route>
            <Route path="/signin" exact component={Login} />
            <Route path="/recoverpass" exact component={RecoverPass} />
          </Switch>
        </MainContent>
      )}
    </>
  );
};

export default Routes;
