import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import {
  FormPatientContainer,
  FormTitleArea,
  FormGroupCN,
  FormGroup,
  LabelForm,
  InputForm,
  FormGroupRow,
  FormGroupRow3,
  TextAreaForm,
  BtnActionsArea,
  BtnGuardar,
  BtnCancelar,
  SmallText,
} from "./FormPatientElements";
import useUser from "../../hooks/useUser";
import { calcEdad } from "../../lib/utilies";

const FormPatient = ({ patient }) => {
  const history = useHistory();

  const {
    user: { id, cm },
    patient: { selPatient, addPatient, updatePatient },
    historia: { selHistory },
    evolution: { selEvolution },
  } = useUser();

  const initialValues = {
    CI: "SinCI",
    name: "",
    nacimiento: "",
    phone: "",
    email: "",
    address: "",
    ped: false,
    id_cm: cm,
    id_user: id,
  };

  const [edad, setEdad] = useState("0");
  const [fpatient, setFpatient] = useState(initialValues);

  useEffect(() => {
    if (patient?._id !== undefined) {
      setFpatient({
        ...fpatient,
        CI: patient.CI,
        name: patient.name,
        nacimiento: patient.nacimiento,
        phone: patient.phone,
        email: patient.email,
        address: patient.address,
        ped: patient.ped,
      });

      const age = calcEdad(patient.nacimiento);
      setEdad(age.edad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdad = () => {
    const age = calcEdad(fpatient.nacimiento);

    if (!age) {
      toast(
        "Hay un problema con la fecha que has insertado, por favor intentalo de nuevo",
        {
          autoClose: 2000,
          type: "warning",
        }
      );
    } else {
      setEdad(age.edad);

      setFpatient({
        ...fpatient,
        ped: age.num > 14 ? false : true,
      });
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setFpatient({
      ...fpatient,
      [e.target.name]: value,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const data = {
      name: fpatient.name,
      CI: fpatient.CI,
      nacimiento: fpatient.nacimiento,
      phone: fpatient.phone,
      email: fpatient.email,
      address: fpatient.address,
      id_cm: fpatient.id_cm,
      ped: fpatient.ped,
      id_user: fpatient.id_user,
    };

    if (patient._id !== undefined) {
      await updatePatient(data, patient._id);
    } else {
      await addPatient(data);

      history.push("/historias");
    }
  };

  return (
    <FormPatientContainer onSubmit={handleSave}>
      <FormTitleArea>Datos del Paciente</FormTitleArea>
      <FormGroupCN>
        <FormGroup>
          <LabelForm>Cedula</LabelForm>
          <InputForm
            required
            type="text"
            placeholder="Inserte cedula de paciente"
            onChange={handleChange}
            value={fpatient.CI}
            name="CI"
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Nombre</LabelForm>
          <InputForm
            required
            type="text"
            placeholder="Inserte nombre completo de paciente"
            onChange={handleChange}
            value={fpatient.name}
            name="name"
          />
        </FormGroup>
      </FormGroupCN>
      <FormGroupRow3>
        <FormGroup>
          <LabelForm>Nacimiento</LabelForm>
          <InputForm
            required
            type="date"
            onChange={handleChange}
            value={fpatient.nacimiento}
            name="nacimiento"
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Edad</LabelForm>
          <InputForm
            isBtn={true}
            type="text"
            onChange={handleChange}
            value={edad || 0}
            name="edad"
            readOnly
            onClick={handleEdad}
          />
          <SmallText>Dale clic para ver la edad</SmallText>
        </FormGroup>
        <FormGroup>
          <LabelForm>Tipo paciente</LabelForm>
          <InputForm
            type="text"
            onChange={handleChange}
            value={fpatient.ped ? "Pediatrico" : "Adulto"}
            name="typepat"
          />
          {/* Hasta los 12 años y hasta 18 años */}
        </FormGroup>
      </FormGroupRow3>
      <FormGroupRow>
        <FormGroup>
          <LabelForm>Teléfono</LabelForm>
          <InputForm
            type="tel"
            placeholder="Inserte teléfono de paciente"
            onChange={handleChange}
            value={fpatient.phone}
            name="phone"
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Email</LabelForm>
          <InputForm
            type="email"
            placeholder="Inserte email de paciente"
            onChange={handleChange}
            value={fpatient.email}
            name="email"
          />
        </FormGroup>
      </FormGroupRow>
      <FormGroup>
        <LabelForm>Dirección</LabelForm>
        <TextAreaForm
          rows="3"
          placeholder="Inserte direccion de paciente"
          onChange={handleChange}
          value={fpatient.address}
          name="address"
        />
      </FormGroup>
      <BtnActionsArea>
        <BtnGuardar type="submit">
          {patient?._id !== undefined ? "Actualizar" : "Guardar"}
        </BtnGuardar>
        <BtnCancelar
          to="/"
          onClick={() => {
            selPatient([]);
            selHistory([]);
            selEvolution([]);
          }}
        >
          Cancelar
        </BtnCancelar>
      </BtnActionsArea>
    </FormPatientContainer>
  );
};

export default FormPatient;
