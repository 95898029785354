import moment from "moment";
import useUser from "../../hooks/useUser";

import {
  ListCitasContainer,
  ListCitasBody,
  ListCitasItems,
  ListCitasList,
  ListDetailDate,
  ListDetailNote,
  ListDetailSubject,
  ListHead,
  ListNull,
  BtnListItemCitas,
  ListItemHead,
  IconDel,
  IconEdit,
  BtnItem,
} from "./ListCitasElements";

const ListCitas = ({ citas }) => {
  const {
    cita: { selCita, delCita },
  } = useUser();
  const citasPendientes = citas.filter((cita) => cita.state === "N");

  return (
    <ListCitasContainer>
      <ListHead color="color">Citas de {moment().format("MMMM")}</ListHead>
      <ListCitasBody>
        {citasPendientes.length > 0 ? (
          <ListCitasList>
            {citasPendientes.map((cita, id) => (
              <ListCitasItems key={id}>
                <ListItemHead>
                  <ListDetailSubject>
                    <b>{cita.name_patient}</b>
                  </ListDetailSubject>
                  <BtnListItemCitas>
                    <BtnItem onClick={() => selCita(cita)}>
                      <IconEdit />
                    </BtnItem>
                    <BtnItem onClick={() => delCita(cita._id)}>
                      <IconDel />
                    </BtnItem>
                  </BtnListItemCitas>
                </ListItemHead>
                <ListDetailDate>
                  <b>Doctor: </b>
                  {cita.name_doctor}
                  <br />
                  {moment(cita.date).format("dddd, DD/MM/YYYY, h:mm a")}
                </ListDetailDate>
                <br />
                {cita.note !== "" && (
                  <ListDetailNote>{cita.note}</ListDetailNote>
                )}
              </ListCitasItems>
            ))}
          </ListCitasList>
        ) : (
          <ListNull>
            <h1>No hay citas programadas pendientes</h1>
          </ListNull>
        )}
      </ListCitasBody>
    </ListCitasContainer>
  );
};

export default ListCitas;
