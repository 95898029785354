import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import useUser from "../../hooks/useUser";
import BuscadorPacientes from "../BuscadorPacientes";
import {
  BtnActionsArea,
  BtnCancelar,
  BtnGuardar,
  FormCitasContainer,
  FormCitasForm,
  FormGroup,
  FormTitleArea,
  InputForm,
  LabelForm,
  OptionSelectField,
  SelectFieldForm,
  TextAreaForm,
} from "./FormCitasElements";

const FormCitas = ({ doctores, patients }) => {
  const {
    patient: { patient, selPatient },
    evolution: { evolution, selEvolution },
    historia: { selHistory },
    user: { id, cm },
    cita: { addCita, cita, updateCita },
  } = useUser();
  const initialForm = {
    id_doctor: "",
    query: "",
    date: `${moment().format("YYYY-MM-DD")}T${moment().format("HH:mm")}`,
    note: "",
  };
  const [form, setForm] = useState(initialForm);
  const refSelDr = useRef(null);

  useEffect(() => {
    if (patient._id !== undefined) {
      setForm({
        ...form,
        query: patient.name,
      });
      if (evolution._id !== undefined) {
        setForm({
          ...form,
          id_doctor: evolution.id_doctor,
        });
      }
    }

    if (cita._id !== undefined) {
      setForm({
        id_doctor: cita.id_doctor,
        query: cita.name_patient,
        date: `${moment(cita.date).format("YYYY-MM-DD")}T${moment().format(
          "HH:mm"
        )}`,
        note: cita.note,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, cita]);

  const handleChange = (e) => {
    const value = e.target.value;
    const { name } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.id_doctor === "") {
      toast("Por favor selecciona el doctor", {
        autoClose: 2000,
        type: "warning",
      });
      refSelDr.current.focus();
    } else {
      if (cita._id === undefined) {
        const name_doctor = doctores.filter(
          (doctor) => doctor._id === form.id_doctor
        )[0].name;

        let data = {
          id_user: id,
          id_doctor: form.id_doctor,
          name_doctor,
          id_cm: cm,
          note: form.note !== "" ? form.note : "No refiere",
          date: form.date,
        };

        if (patient._id !== undefined) {
          data = {
            ...data,
            id_patient: patient._id,
            name_patient: patient.name,
          };
        } else {
          data = {
            ...data,
            name_patient: form.query,
          };
        }

        if (evolution._id !== undefined) {
          data = {
            ...data,
            id_evo: evolution._id,
          };
        }

        await addCita(data);
      } else if (cita._id !== undefined) {
        const name_doctor = doctores.filter(
          (doctor) => doctor._id === form.id_doctor
        )[0].name;
        const data = {
          id_user: id,
          id_doctor: form.id_doctor,
          name_doctor,
          id_cm: cm,
          note: form.note !== "" ? form.note : "No refiere",
          date: form.date,
        };

        await updateCita(data, cita._id);
      }
    }
  };

  return (
    <FormCitasContainer>
      <FormTitleArea>Agrega una nueva cita</FormTitleArea>
      <FormCitasForm onSubmit={handleSubmit}>
        <FormGroup>
          <LabelForm>Doctores</LabelForm>
          <SelectFieldForm
            value={form.id_doctor}
            name="id_doctor"
            onChange={handleChange}
            ref={refSelDr}
          >
            <OptionSelectField value="null">
              Selecciona doctor
            </OptionSelectField>
            {doctores.map((doctor, id) => (
              <OptionSelectField value={doctor._id} key={id}>
                {doctor.name}
              </OptionSelectField>
            ))}
          </SelectFieldForm>
        </FormGroup>
        <FormGroup>
          <LabelForm>Nombre Paciente:</LabelForm>
          <BuscadorPacientes
            patients={patients}
            query={form.query}
            handleChange={handleChange}
            setClickQuery={(name) => setForm({ ...form, query: name })}
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Fecha:</LabelForm>
          <InputForm
            type="datetime-local"
            value={form.date}
            name="date"
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <LabelForm>Nota:</LabelForm>
          <TextAreaForm
            row="3"
            placeholder="Nota de Cita"
            value={form.note}
            name="note"
            onChange={handleChange}
          />
        </FormGroup>
        <BtnActionsArea>
          <BtnGuardar type="submit">
            {cita._id === undefined ? "Guardar" : "actualizar"}
          </BtnGuardar>
          <BtnCancelar
            to="/"
            onClick={() => {
              selPatient([]);
              selEvolution([]);
              selHistory([]);
            }}
          >
            Cancelar
          </BtnCancelar>
        </BtnActionsArea>
      </FormCitasForm>
    </FormCitasContainer>
  );
};

export default FormCitas;
