import styled from "styled-components";
import { Link } from "react-router-dom";

export const FormCitasContainer = styled.div`
  width: 100%;
  padding-right: 20px;

  @media screen and (max-width: 480px) {
    padding: 5px 8px;
  }
`;

export const FormCitasForm = styled.form``;

export const FormTitleArea = styled.h1`
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 10px;
`;

export const FormGroup = styled.div`
  text-align: left;
  padding: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LabelForm = styled.label`
  font-size: small;
  color: gray;
`;

export const InputForm = styled.input`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
  background: ${({ isBtn }) => (isBtn ? "#d2ffee" : "#fff")};
`;

export const TextAreaForm = styled.textarea`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
`;

export const SelectFieldForm = styled.select`
  padding: 5px 10px;
  font-family: inherit;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;

  &:focus option {
    background-color: #ccfff5;
  }
`;

export const OptionSelectField = styled.option`
  border: 1px solid #eae5e5;
  padding: 5px 10px;
  box-sizing: border-box;
  outline: none;
`;

export const BtnActionsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

export const BtnGuardar = styled.button`
  cursor: pointer;
  text-decoration: none;
  width: 20%;
  padding: 10px 20px;
  font-family: inherit;
  font-size: 14px;
  text-align: center;
  background: #5ac8fa;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;

  @media screen and (max-width: 1024px) {
    width: 40%;
  }
`;

export const BtnCancelar = styled(Link)`
  margin-left: 20px;
  cursor: pointer;
  width: 20%;
  background: #eee;
  text-decoration: none;
  padding: 10px 20px;
  color: #757575;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;

  @media screen and (max-width: 1024px) {
    width: 40%;
  }
`;
