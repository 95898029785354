import styled from "styled-components";

export const CardEvoContainer = styled.div`
  width: 320px;
  height: 350px;
  padding: 20px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  margin: 10px;
  cursor: pointer;
  overflow-y: auto;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: right;
  font-size: 11px;
  color: gray;
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: left;
  font-size: 12px;
  flex-direction: column;
  margin-top: 10px;
`;

export const GroupDataCard = styled.div`
  margin: 5px 0;
  display: flex;
  justify-content: left;
  flex-direction: column;
`;

export const LabelCard = styled.label`
  font-weight: 600;
`;

export const DataCard = styled.span``;

export const DataListCard = styled.ul``;

export const DataListItemCard = styled.li`
  padding: 3px 5px;
  list-style: none;
`;
