import styled from "styled-components";

export const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: inherit;
  color: #455a64;

  background: #fffcdc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #fffcdc,
    #ffffff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #fffcdc,
    #ffffff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;
export const HomeContent = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: -220px;

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;
export const HomeHero = styled.div`
  font-size: 3rem;
  padding: 70px;

  @media screen and (max-width: 480px) {
    padding: 20px;
  }
`;
export const HomeDescription = styled.div``;
