import {
  LoginContainer,
  LoginForm,
  H1Form,
  InputForm,
  FormGroup,
  LabelForm,
  BtnLoginForm,
} from "./LoginElements";

const RecoverPass = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit}>
          <H1Form>Recupera tu contraseña</H1Form>
          <FormGroup>
            <LabelForm>Email de usuario</LabelForm>
            <InputForm
              type="email"
              placeholder="Inserta el email del usuario"
            ></InputForm>
          </FormGroup>
          <FormGroup>
            <BtnLoginForm type="submit">Recuperar Contraseña</BtnLoginForm>
          </FormGroup>
        </LoginForm>
      </LoginContainer>
    </>
  );
};

export default RecoverPass;
