import styled from "styled-components";
import { FaTimesCircle } from "react-icons/fa";
import { FcSettings, FcAreaChart, FcList, FcTemplate } from "react-icons/fc";
import { Link } from "react-router-dom";

export const SidebarContainer = styled.div`
  position: fixed;
  z-index: 1;
  /* width: 26%; */
  padding: 0 30px;
  height: 100vh;
  background: #eae8e8;
  display: grid;
  grid-template-rows: 1fr 70% 1fr;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.5s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 0 50px;
  }
`;

export const SideBarBtnAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  /* padding: 0 20px; */
`;

export const LogoLink = styled(Link)``;

export const LogoCemedsys = styled.img`
  max-width: 80px;
`;

export const SettingsIcon = styled(FcSettings)``;

export const CloseIcon = styled(FaTimesCircle)`
  color: #455a64;
`;

export const IconClose = styled.div`
  background: transparent;
  font-size: 1.8rem;
  cursor: pointer;
  outline: none;
`;
export const IconSettings = styled(Link)`
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarWrapper = styled.div`
  color: #455a64;
`;

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 80px);

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(5, 80px);
  }
`;

export const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #455a64;
  cursor: pointer;

  &:hover {
    color: #ff2e00;
    font-weight: 600;
    transition: 0.2s ease-in-out;
  }

  @media screen and (max-width: 480px) {
    justify-content: left;
    font-size: 2rem;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SidebarRoute = styled(Link)`
  border-radius: 30px;
  background: #36ffbe;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 700;
  padding: 16px 48px;
  color: #455a64;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s easy-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #00ffad;
    color: #010606;
  }
`;

export const IconEvolucion = styled(FcAreaChart)`
  margin-right: 10px;
  font-size: 2rem;
`;

export const IconHistoria = styled(FcList)`
  margin-right: 10px;
  font-size: 2rem;
`;

export const IconRecetas = styled(FcTemplate)`
  margin-right: 10px;
  font-size: 2rem;
`;

export const IconPaciente = styled.img`
  max-width: 25px;
  margin-right: 10px;
`;

export const IconCita = styled.img`
  margin-right: 10px;
  max-width: 25px;
`;
