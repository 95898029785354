import styled from "styled-components";

export const CitasContainer = styled.div`
  width: 100%;
  overflow: hidden;

  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CitasContent = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const CitasForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  @media screen and (max-width: 480px) {
    margin: 20px 0;
  }
`;

export const CitasList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 500px;
  border-radius: 10px;
  @media screen and (max-width: 480px) {
    margin: 20px 0;
  }
`;
