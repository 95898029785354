import { useEffect, useState } from "react";
import moment from "moment";

import useUser from "../../hooks/useUser";
import BtnActions from "../BtnActions";
import InfoEvolution from "../Information/InfoEvolution";
import InfoPatient from "../Information/InfoPatient";
import {
  BtnCancelar,
  BtnCertEnfRepAction,
  BtnGuardar,
  CertEnfRepForm,
  CertEnfRepFormGroup,
  CertEnfRepFormInput,
  CertEnfRepFormLabel,
  CertificadosBtn,
  CertificadosContainer,
  CertificadosTittle,
} from "./CertificadosElements";
import { calcEdad } from "../../lib/utilies";

const Certificados = () => {
  const {
    patient: { patient },
    evolution: { evolution },
    user: { cm },
    token,
    printCertNSano,
    printCertEnf,
    printCertRep,
  } = useUser();
  const URIROOT = process.env.REACT_APP_URIROOT;

  const [dcm, setDCM] = useState([]);
  const [doctor, setDoctor] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };
    const fetchCM = async () => {
      const res = await fetch(`${URIROOT}/cm/${cm}`, config);
      const datacm = await res.json();

      setDCM(datacm.data[0]);
    };

    const fetchDr = async () => {
      const res = await fetch(
        `${URIROOT}/doctor/${evolution.id_doctor}`,
        config
      );
      const datadr = await res.json();

      setDoctor(datadr.data[0]);
    };

    fetchCM();
    fetchDr();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initValues = {
    ltrabajo: "",
    diasr: 1,
    rfdesde: moment().format("YYYY-MM-DD"),
    rfhasta: moment().format("YYYY-MM-DD"),
  };

  const [isRep, setIsRep] = useState(false);
  const [isEnf, setIsEnf] = useState(false);
  const [formEnfRep, setFormEnfRep] = useState(initValues);

  const handleCertNSano = async () => {
    const idcert = `CNS${patient.CI}${String(moment().format("DDMMYYYY"))}`;
    const fecha = `${dcm.city}, ${moment().format("DD")} de ${moment().format(
      "MMMM"
    )} del ${moment().format("YYYY")}`;
    const age = calcEdad(patient.nacimiento).edad;

    const data = {
      codcm: dcm.codigo,
      idcert,
      cmname: dcm.name,
      cmphone: dcm.phone,
      cmaddress: dcm.address,
      fecha,
      name: patient.name,
      ci: patient.CI,
      age,
      drname: doctor.name,
      drci: doctor.CI,
      dresp: doctor.especialidad,
      dremail: doctor.email,
    };

    await printCertNSano(data);
  };

  const handleCertEnferm = () => {
    setIsRep(false);
    setIsEnf(!isEnf);
  };

  const handleGenerateEnf = async (e) => {
    e.preventDefault();

    const idcert = `CEN${patient.CI}${String(moment().format("DDMMYYYY"))}`;
    const fecha = `${dcm.city}, ${moment().format("DD")} de ${moment().format(
      "MMMM"
    )} del ${moment().format("YYYY")}`;

    const data = {
      codcm: dcm.codigo,
      idcert,
      cmname: dcm.name,
      cmphone: dcm.phone,
      cmaddress: dcm.address,
      fecha,
      name: patient.name,
      ci: patient.CI,
      diagnostico: evolution.diagnosis,
      notadiag: evolution.notesdiag,
      ltrabajo: formEnfRep.ltrabajo,
      drname: doctor.name,
      drci: doctor.CI,
      dresp: doctor.especialidad,
      dremail: doctor.email,
    };

    await printCertEnf(data);
  };

  const handleCertEnfermReposo = () => {
    setIsRep(!isRep);
    setIsEnf(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;

    setFormEnfRep({
      ...formEnfRep,
      [e.target.name]: value,
    });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();

    const idcert = `CER${patient.CI}${String(moment().format("DDMMYYYY"))}`;
    const fecha = `${dcm.city}, ${moment().format("DD")} de ${moment().format(
      "MMMM"
    )} del ${moment().format("YYYY")}`;

    const data = {
      codcm: dcm.codigo,
      idcert, // combinacion CER+cedulaPatient+DDMMYYYY
      cmname: dcm.name,
      cmphone: dcm.phone,
      cmaddress: dcm.address,
      fecha, // combinar ciudadCM, fecha
      name: patient.name,
      ci: patient.CI,
      diagnostico: evolution.diagnosis,
      notadiag: evolution.notesdiag,
      ltrabajo: formEnfRep.ltrabajo,
      diasr: formEnfRep.diasr,
      rfdesde: moment(formEnfRep.rfdesde).format("dddd, DD/MM/YYYY"),
      rfhasta: moment(formEnfRep.rfhasta).format("dddd, DD/MM/YYYY"),
      drname: doctor.name, // cargar info de doctor
      drci: doctor.CI, // cargar info de doctor
      dresp: doctor.especialidad, // cargar info de doctor
      dremail: doctor.email, // cargar info de doctor
    };

    await printCertRep(data);
  };

  const handleChangeDays = (e) => {
    const value = e.target.value;

    if (e.target.name === "rfdesde") {
      const dias = formEnfRep.diasr;
      const hasta = moment(value).add(dias - 1, "day");
      setFormEnfRep({
        ...formEnfRep,
        rfdesde: value,
        rfhasta: moment(hasta).format("YYYY-MM-DD"),
      });
    } else if (e.target.name === "rfhasta") {
      setFormEnfRep({
        ...formEnfRep,
        rfhasta: value,
      });
    } else if (e.target.name === "diasr") {
      const desde = formEnfRep.rfdesde;
      const hasta = moment(desde).add(value - 1, "day");

      setFormEnfRep({
        ...formEnfRep,
        diasr: value,
        rfhasta: moment(hasta).format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <CertificadosContainer>
      <CertificadosTittle>Certificados</CertificadosTittle>
      <BtnActions />
      <InfoPatient patient={patient} />
      <InfoEvolution evolution={evolution} />
      {patient.ped && (
        <CertificadosBtn onClick={handleCertNSano}>
          Niño Sano 👶🏻
        </CertificadosBtn>
      )}
      <CertificadosBtn onClick={handleCertEnferm}>
        Enfermedad 🤒
      </CertificadosBtn>
      {isEnf && (
        <CertEnfRepForm onSubmit={handleGenerateEnf}>
          <CertEnfRepFormGroup>
            <CertEnfRepFormLabel>Labora o estudia:</CertEnfRepFormLabel>
            <CertEnfRepFormInput
              required
              type="text"
              onChange={handleChange}
              name="ltrabajo"
              value={formEnfRep.ltrabajo}
              placeholder="Trabajo o institución educativa  del paciente"
            />
          </CertEnfRepFormGroup>
          <BtnCertEnfRepAction>
            <BtnGuardar type="submit">Generar</BtnGuardar>
            <BtnCancelar
              onClick={() => {
                setIsRep(false);
                setFormEnfRep(initValues);
              }}
            >
              Cancelar
            </BtnCancelar>
          </BtnCertEnfRepAction>
        </CertEnfRepForm>
      )}
      <CertificadosBtn onClick={handleCertEnfermReposo}>
        Enfermedad y reposo 🤕
      </CertificadosBtn>
      {isRep && (
        <CertEnfRepForm onSubmit={handleGenerate}>
          <CertEnfRepFormGroup>
            <CertEnfRepFormLabel>Labora o estudia:</CertEnfRepFormLabel>
            <CertEnfRepFormInput
              type="text"
              onChange={handleChange}
              name="ltrabajo"
              value={formEnfRep.ltrabajo}
              placeholder="Trabajo o institución educativa  del paciente"
            />
          </CertEnfRepFormGroup>
          <CertEnfRepFormGroup>
            <CertEnfRepFormLabel>Dias de reposo:</CertEnfRepFormLabel>
            <CertEnfRepFormInput
              type="number"
              min="1"
              name="diasr"
              onChange={handleChangeDays}
              value={formEnfRep.diasr}
              placeholder="Días de reposo"
            />
          </CertEnfRepFormGroup>
          <CertEnfRepFormGroup>
            <CertEnfRepFormLabel>Desde:</CertEnfRepFormLabel>
            <CertEnfRepFormInput
              type="date"
              name="rfdesde"
              onChange={handleChangeDays}
              value={formEnfRep.rfdesde}
            />
          </CertEnfRepFormGroup>
          <CertEnfRepFormGroup>
            <CertEnfRepFormLabel>Hasta:</CertEnfRepFormLabel>
            <CertEnfRepFormInput
              type="date"
              name="rfhasta"
              onChange={handleChangeDays}
              value={formEnfRep.rfhasta}
            />
          </CertEnfRepFormGroup>
          <BtnCertEnfRepAction>
            <BtnGuardar type="submit">Generar</BtnGuardar>
            <BtnCancelar
              onClick={() => {
                setIsRep(false);
                // setFormEnfRep(initValues);
              }}
            >
              Cancelar
            </BtnCancelar>
          </BtnCertEnfRepAction>
        </CertEnfRepForm>
      )}
    </CertificadosContainer>
  );
};

export default Certificados;
