import styled from "styled-components";
import { Link } from "react-router-dom";

export const FormEvolutionContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FormTitleArea = styled.h1`
  font-size: 16px;
  margin: 0;
`;

export const SelectFieldForm = styled.select`
  padding: 5px 10px;
  font-family: inherit;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;

  &:focus option {
    background-color: #ccfff5;
  }
`;

export const OptionSelectField = styled.option`
  border: 1px solid #eae5e5;
  padding: 5px 10px;
  box-sizing: border-box;
  outline: none;
`;

export const FormGrid2Col = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: top;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const FormGrid3Col = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: top;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const FormGroup = styled.div`
  text-align: left;
  padding: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FormGroup2 = styled.div`
  text-align: right;
  padding: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LabelForm = styled.label`
  font-size: small;
  color: gray;
`;

export const InputForm = styled.input`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
  background: ${({ isBtn }) => (isBtn ? "#d2ffee" : "#fff")};
`;

export const TextAreaForm = styled.textarea`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
`;

export const BtnActionsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

export const BtnGuardar = styled.button`
  cursor: pointer;
  text-decoration: none;
  width: 20%;
  padding: 10px 20px;
  font-family: inherit;
  font-size: 14px;
  text-align: center;
  background: #5ac8fa;
  border-radius: 10px;
  border: none;
  /* box-shadow: 5px 7px 11px -6px #000000; */
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    width: 40%;
  }
`;

export const BtnCancelar = styled(Link)`
  margin-left: 20px;
  cursor: pointer;
  width: 20%;
  background: #eee;
  text-decoration: none;
  padding: 10px 20px;
  color: #757575;
  border: none;
  border-radius: 10px;
  /* box-shadow: 5px 7px 11px -6px #000000; */
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    width: 40%;
  }
`;

export const ResultsAreaSearch = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 0 5px;
  z-index: 1;
  height: 100px;
  overflow-y: auto;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
`;

export const ResultItemSearch = styled.li`
  list-style: none;
  font-size: 12px;
  color: inherit;
  cursor: pointer;
  padding: 2px 5px;
  border: 1px solid #f1f0f0;
  margin: 2px 0;
  font-size: 14px;
`;

export const AreaLisForm = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

export const ListFormItem = styled.li`
  list-style: none;
  font-size: 12px;
  color: inherit;
  cursor: pointer;
  border: 1px solid #f1f0f0;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckAreaForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckInputForm = styled.input`
  margin-left: 5px;
`;

export const SearchArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  margin-bottom: 5px;
`;

export const LabelSearchArea = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 0 0 10px;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-size: 12px;

  background: #8cf1dd; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #78ffd6,
    #8cf1dd
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #78ffd6, #8cf1dd);
`;

export const InputSearchArea = styled.input`
  padding: 5px 10px;
  font-family: inherit;
  width: 100%;
  border: 1px solid #eae5e5;
  border-radius: 0 10px 10px 0;
  background: #fff;
  box-sizing: border-box;
  outline: none;
  background: ${({ isBtn }) => (isBtn ? "#d2ffee" : "#fff")};
`;
