import { useLocation } from "react-router-dom";

import Home from "../../pages/Home";
import Dashboard from "../../pages/Dashboard";
import Login from "../../pages/Login";

import useUser from "../../hooks/useUser";

const MainContent = ({ children }) => {
  const location = useLocation();
  const { auth } = useUser();

  if (location.pathname === "/" && auth) {
    return <Dashboard />;
  } else if (location.pathname === "/" && !auth) {
    return <Home />;
  } else if (location.pathname !== "/" && auth) {
    return children;
  } else if (location.pathname !== "/" && !auth) {
    return <Login />;
  }
};

export default MainContent;
