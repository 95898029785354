import styled from "styled-components";

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: inherit;
  justify-content: center;
  align-items: center;
  border: 1px solid #eae5e5;
  padding: 0 10px;
  width: 100%;
  font-size: 12px;
  border-radius: 10px;
  margin: 10px 0;
`;

export const InformationTitle = styled.span`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
`;

export const InformationContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InformationBit = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export const InformationDetail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const InformationData = styled.div`
  font-size: 11px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InformationGroupData = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 2px;
  margin: 0 5px;

  @media screen and (max-width: 480px) {
    padding: 0;
    /* flex-wrap: wrap; */
  }
`;

export const InformationLabelField = styled.label`
  font-weight: 600;
  margin-right: 10px;
`;
export const InformationLabelData = styled.label``;

export const MessageInformationNull = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  color: gray;
`;
