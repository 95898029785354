import styled from "styled-components";
import { FcMenu } from "react-icons/fc";
import { Link } from "react-router-dom";

export const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffdddd;
  height: 60px;
  padding: 10px 15px;
  overflow: hidden;
`;

export const LogoWrapper = styled(Link)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;

  h1 {
    color: black;
    font-size: 22px;
    font-weight: 700;
  }
`;

export const IconMenu = styled(FcMenu)`
  font-size: 2rem;
  cursor: pointer;
`;

export const LogoImage = styled.img`
  max-width: 160px;
`;

export const BtnActionsNavbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BtnProfileWrap = styled.div`
  cursor: pointer;
`;

export const ImgMedic = styled.img`
  max-width: 40px;
`;

export const BtnLogin = styled(Link)`
  border-radius: 15px;
  border: none;
  padding: 10px 15px;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 1px;
  /* background: #ff9f43; */

  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  &:hover {
    background: #d45efb;
  }
`;
