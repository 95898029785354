import styled from "styled-components";

export const RecetasContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RecetasContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding: 10px;
`;

export const BtnActionsArea = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

export const BtnActionsButton = styled.button`
  font-family: inherit;
  border: none;
  outline: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  background: aquamarine;
  padding: 8px 15px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background: #00dc76;
    color: white;
  }
`;
