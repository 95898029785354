import {
  HomeContainer,
  HomeContent,
  HomeHero,
  HomeDescription,
} from "./HomeElements";

const Home = () => {
  return (
    <>
      <HomeContainer>
        <HomeContent>
          <HomeHero>Bienvenido a Cemedsys</HomeHero>
          <HomeDescription>
            Administra, Registra y Genera valor agregado. <br />
            Con los datos de tus pacientes
          </HomeDescription>
        </HomeContent>
      </HomeContainer>
    </>
  );
};

export default Home;
