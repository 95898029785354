import moment from "moment";
import { useEffect, useState } from "react";

import {
  MainContentContainer,
  HeroContainer,
  CardDataContainer,
  CardData,
  CardDataTitle,
  CardDataVariable,
  CardDataPrincipal,
  CardDataPrincipalData,
  CardDataPrincipalDetail,
} from "../../components/MainContent/MainContentElements";
import useUser from "../../hooks/useUser";

const Dashboard = () => {
  const URIROOT = process.env.REACT_APP_URIROOT;
  const {
    token,
    user: { cm },
  } = useUser();

  const [patients, setPatients] = useState(0);
  const [newPatients, setNewPatients] = useState(0);
  const [evosToday, setEvosToday] = useState(0);
  const [evosToMonth, setEvosToMonth] = useState(0);
  const [datesToday, setDatesToday] = useState(0);
  const [datesPendings, setDatesPendings] = useState(0);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };

    const fetchEvosCM = async () => {
      const res = await fetch(`${URIROOT}/evolution/all/${cm}`, config);
      const data = await res.json();

      setEvosToday(patientsToday(data.data));
      setEvosToMonth(filterEvosToMonth(data.data));
    };

    fetchEvosCM();

    const filterEvosToMonth = (data) => {
      const evosMonth = data.filter(
        (evo) =>
          moment(evo.registedAt).format("MM") === moment().format("MM") &&
          moment(evo.registedAt).format("YYYY") === moment().format("YYYY")
      );

      return evosMonth.length;
    };

    const fetchPatients = async () => {
      const res = await fetch(`${URIROOT}/patient/all/${cm}`, config);
      const data = await res.json();

      setPatients(data.counter);
      setNewPatients(filterNewPatients(data.data));
    };

    fetchPatients();

    const filterNewPatients = (data) => {
      const newPatients = data.filter(
        (pat) =>
          moment(pat.registedAt).format("DD/MM/YYYY") ===
          moment().format("DD/MM/YYYY")
      );

      return newPatients.length;
    };

    const patientsToday = (data) => {
      const patients = data.filter(
        (evo) =>
          moment(evo.registedAt).format("DD/MM/YYYY") ===
          moment().format("DD/MM/YYYY")
      );
      return patients.length;
    };

    const fetchDates = async () => {
      const res = await fetch(`${URIROOT}/schedule/all/${cm}`, config);
      const data = await res.json();

      setDatesToday(filterDatesToday(data.data));
      setDatesPendings(filterDatesPendings(data.data));
    };

    fetchDates();

    const filterDatesPendings = (data) => {
      const datesPendings = data.filter(
        (date) => new Date(date.date) >= new Date()
      );

      return datesPendings.length;
    };

    const filterDatesToday = (data) => {
      const datesToday = data.filter(
        (date) =>
          moment(date.date).format("DD/MM/YYYY") ===
          moment().format("DD/MM/YYYY")
      );

      return datesToday.length;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContentContainer>
      <HeroContainer>
        <h1>Bienvenido</h1>
      </HeroContainer>

      <CardDataContainer>
        <CardDataPrincipal>
          <CardDataPrincipalData>
            <CardDataVariable>{evosToday}</CardDataVariable>
            <CardDataTitle>Pacientes atendidos</CardDataTitle>
          </CardDataPrincipalData>
          <CardDataPrincipalDetail>
            <span>
              <b>Pacientes: </b> {patients} <br />
            </span>
            <span>
              <b>Pacientes nuevos: </b> {newPatients} <br />
            </span>
          </CardDataPrincipalDetail>
        </CardDataPrincipal>
        <CardData colorCard="#ff7675">
          <CardDataVariable>{evosToMonth}</CardDataVariable>
          <CardDataTitle>Atenciones en el mes</CardDataTitle>
        </CardData>
      </CardDataContainer>
      <CardDataContainer>
        <CardData colorCard="#74b9ff">
          <CardDataVariable>{datesToday}</CardDataVariable>
          <CardDataTitle>Citas para hoy</CardDataTitle>
        </CardData>
        <CardData colorCard="#a29bfe">
          <CardDataVariable>{datesPendings}</CardDataVariable>
          <CardDataTitle>Citas pendientes</CardDataTitle>
        </CardData>
      </CardDataContainer>
    </MainContentContainer>
  );
};

export default Dashboard;
