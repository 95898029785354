import moment from "moment";
import { useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import {
  ViewPrescContainer,
  ViewPrescList,
  ViewPrescListItem,
  ViewPrescListItemNull,
} from "./ViewPrescElements";

const ViewPresc = ({ doctores, dcm }) => {
  const URIROOT = process.env.REACT_APP_URIROOT;
  const {
    patient: { patient },
    user: { cm },
    token,
  } = useUser();
  const [prescs, setPrescs] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };

    const fetchPresc = async () => {
      const res = await fetch(
        `${URIROOT}/prescription/${cm}/${patient._id}`,
        config
      );
      const datapresc = await res.json();

      setPrescs(datapresc.data);
    };

    fetchPresc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  return (
    <ViewPrescContainer>
      <ViewPrescList>
        {prescs.length > 0 ? (
          prescs.map((receta) => (
            <ViewPrescListItem>
              <b>Fecha:</b>{" "}
              {moment(receta.createdAt).format("dddd, DD/MM/YYYY, HH:mm")}{" "}
              {receta.diagnosis.length > 0 ||
                (receta.notasDiag !== "" && (
                  <>
                    <br />
                    <b>Diagnóstico:</b>
                    {receta.diagnostico.length > 0 && (
                      <>
                        <br />
                        <ul>
                          {receta.diagnostico.map((diag) => (
                            <li>
                              {diag.d} - CIE10 {diag.c} -{" "}
                              {diag.t ? "Definitivo" : "Presuntivo"}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    {receta.notasDiag !== "" && (
                      <>
                        <br />
                        {receta.notasDiag}
                      </>
                    )}
                  </>
                ))}
            </ViewPrescListItem>
          ))
        ) : (
          <ViewPrescListItemNull>
            No existen recetas registradas
          </ViewPrescListItemNull>
        )}
      </ViewPrescList>
    </ViewPrescContainer>
  );
};

export default ViewPresc;
