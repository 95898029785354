import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import {
  EvolucionesContainer,
  MenuEvoluciones,
  EvolucionesContent,
} from "./EvolucionesElements";

import BtnActions from "../../components/BtnActions";
import Buscador from "../../components/Buscador";
import InfoPatient from "../../components/Information/InfoPatient";
// import InfoHistory from "../../components/Information/InfoHistory";
import FormEvolution from "../../components/FormEvolution";
import useUser from "../../hooks/useUser";
import BtnAdd from "../../components/BtnAdd";
import ListEvolutions from "../../components/ListEvolutions";

const Evoluciones = () => {
  const {
    user: { cm },
    patient: { patient },
    evolution: { evolution, selEvolution },
    // historia: { historia },
    token,
  } = useUser();

  const [patients, setPatients] = useState([]);
  const [evolutions, setEvolutions] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const refSearch = useRef(null);

  const URIROOT = process.env.REACT_APP_URIROOT;

  const fetchPatients = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };
    const res = await fetch(`${URIROOT}/patient/all/${cm}`, config);
    const data = await res.json();

    setPatients(data.data);
  };

  const fetchEvolutions = async (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/evolution/${cm}/${id}`, config);
    const data = await res.json();

    setEvolutions(data.data);
  };

  useEffect(() => {
    fetchPatients();
    fetchEvolutions(patient._id);

    if (patient._id !== undefined) {
      setIsAdd(true);
    } else {
      setIsAdd(false);
    }

    if (evolution._id !== undefined) {
      setIsNew(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsAdd = () => {
    setIsAdd(true);
  };

  const handleNoIsAdd = () => {
    setIsAdd(false);
    setEvolutions([]);
    setIsNew(false);
  };

  const handleShowAdd = () => {
    if (patient._id === undefined) {
      refSearch.current.focus();
      toast("Primero consulta un paciente para registrar una nueva atención", {
        autoClose: 2000,
        type: "warning",
      });
    } else if (patient._id !== undefined) {
      setIsNew(!isNew);
      selEvolution([]);
    }
  };

  const handleShowFormToUpdate = () => {
    setIsNew(true);
  };

  const renderContent = () => {
    if (evolutions.length > 0 && !isNew) {
      return (
        <ListEvolutions
          evolutions={evolutions}
          showFormToUpdate={handleShowFormToUpdate}
        />
      );
    } else if (isNew) {
      return <FormEvolution />;
    }
  };

  return (
    <EvolucionesContainer>
      <MenuEvoluciones>
        <Buscador
          patients={patients}
          formShow={handleIsAdd}
          formNoShow={handleNoIsAdd}
          show={isAdd}
          refSearch={refSearch}
        />
        <BtnAdd showForm={handleShowAdd} />
      </MenuEvoluciones>
      <BtnActions />
      <EvolucionesContent>
        {patient._id !== undefined && <InfoPatient patient={patient} />}
        {renderContent()}
      </EvolucionesContent>
    </EvolucionesContainer>
  );
};

export default Evoluciones;
