// import { useState } from 'react';
// import BtnMore from '../../BtnMore'
import {
  DataCard,
  DataListCard,
  DataListItemCard,
} from "../../CardEvo/CardEvoElements";
import {
  InformationBit,
  InformationContainer,
  InformationContent,
  InformationGroupData,
  InformationLabelData,
  InformationLabelField,
} from "../InformationtElements";

const InfoEvolution = ({ evolution }) => {
  // const [moreInfo, setMoreInfo] =useState()
  // const handleShowInfo = () => setMoreInfo(!moreInfo);

  return (
    <InformationContainer>
      <InformationContent>
        <InformationBit>
          <InformationGroupData>
            <InformationLabelField>Motivo:</InformationLabelField>
            <InformationLabelData>{evolution.motive}</InformationLabelData>
          </InformationGroupData>
          <InformationGroupData>
            <InformationLabelField>Diagnóstico:</InformationLabelField>
            <DataListCard>
              {evolution.diagnosis.length > 0 &&
                evolution.diagnosis.map((diag, i) => (
                  <DataListItemCard key={i}>
                    {diag.d} -{" "}
                    {diag.t === undefined
                      ? "Definitivo"
                      : diag.t
                      ? "Definitivo"
                      : "Presuntivo"}
                  </DataListItemCard>
                ))}
              <DataCard>{evolution.notesdiag}</DataCard>
            </DataListCard>
          </InformationGroupData>
        </InformationBit>
      </InformationContent>
      {/* <BtnMore showInfo={handleShowInfo} /> */}
    </InformationContainer>
  );
};

export default InfoEvolution;
