import { useReducer } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import {
  INIT,
  LOADING,
  LOGIN_SUCCESS,
  FAIL,
  SUCCESS,
  PATIENT_SUCCESS,
  HIST_SUCCESS,
  EVO_SUCCESS,
  CITA_SUCCESS,
} from "../types";
import UserContext from "./UserContext";
import UserReducer from "./UserReducer";

const URIPDF = process.env.REACT_APP_URI_PDF;
const URIROOT = process.env.REACT_APP_URIROOT;

const UserState = ({ children }) => {
  const initialState = {
    user: [],
    auth: false,
    loading: false,
    token: null,
    message: null,
    patient: [],
    historia: [],
    evolution: [],
    cita: [],
  };

  const [state, dispatch] = useReducer(UserReducer, initialState);
  const session = JSON.parse(localStorage.getItem("userSession"));
  const history = useHistory();

  const validateToken = async (token) => {
    dispatch({
      type: LOADING,
    });

    const config = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-verify": token,
      },
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/auth/user`, config);
    const data = await res.json();

    if (data.success) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          message: `Bienvenido de nuevo ${session.user.username}`,
          user: session.user,
          token: session.token,
        },
      });
    } else {
      toast(data.message, {
        autoClose: 2000,
        type: "warning",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.message,
        },
      });

      localStorage.clear();
    }
  };

  const login = async (signin) => {
    dispatch({
      type: LOADING,
    });

    const config = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(signin),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/user/signin`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.message,
        },
      });
    } else {
      toast(data.message, {
        autoClose: 2000,
        type: "success",
      });

      localStorage.setItem(
        "userSession",
        JSON.stringify({
          token: data.token,
          user: data.profile,
        })
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: data.profile,
          message: data.message,
          token: data.token,
        },
      });
    }
  };

  const logout = async () => {
    dispatch({
      type: LOADING,
    });

    localStorage.clear();
    dispatch({
      type: INIT,
    });

    history?.push("/");
  };

  const signup = async (user) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(user),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/user/signup`, config);
    const data = await res.json();

    if (!data.success) {
      dispatch({
        type: FAIL,
        payload: { message: data.message },
      });
    }

    dispatch({
      type: SUCCESS,
      payload: {
        message: data.message,
      },
    });
  };

  const updateUser = async (modify, id) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));

    const config = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(modify),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/user/${id}`, config);
    const data = await res.json();

    if (!data.success) {
      dispatch({
        type: FAIL,
        payload: { message: data.message },
      });
    }

    dispatch({
      type: SUCCESS,
      payload: {
        message: data.message,
      },
    });
  };

  const addPatient = async (info) => {
    const { token } = JSON.parse(localStorage.getItem("userSession"));

    dispatch({
      type: LOADING,
    });

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(info),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/patient`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: PATIENT_SUCCESS,
      payload: {
        patient: data.data,
        message: data.message,
      },
    });
  };

  const updatePatient = async (info, id) => {
    const { token } = JSON.parse(localStorage.getItem("userSession"));

    dispatch({
      type: LOADING,
    });

    const config = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(info),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/patient/${id}`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: PATIENT_SUCCESS,
      payload: {
        patient: [],
        message: data.message,
      },
    });
  };

  const selPatient = (patient) => {
    dispatch({
      type: LOADING,
    });

    dispatch({
      type: PATIENT_SUCCESS,
      payload: {
        patient,
        message: null,
      },
    });
  };

  const selHistory = (historia) => {
    dispatch({
      type: LOADING,
    });

    dispatch({
      type: HIST_SUCCESS,
      payload: {
        historia,
        message: null,
      },
    });
  };

  const printHistory = async (hist) => {
    try {
      dispatch({
        type: LOADING,
      });

      await axios
        .post(`${URIPDF}/historia`, hist)
        .then(() =>
          axios.get(`${URIPDF}/pdf/historia/${hist.tipo}`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const dblob = new Blob([res.data], { type: "application/pdf" });

          saveAs(dblob, `${hist.idhist}`);
          toast(`generado documento historia ${hist.idhist}.pdf`, {
            autoClose: 2000,
            type: "success",
          });
          dispatch({
            type: SUCCESS,
            payload: {
              message: `generado documento historia ${hist.idhist}.pdf`,
            },
          });
        });
    } catch (error) {
      dispatch({
        type: FAIL,
        payload: {
          message: error.message,
        },
      });
      toast(error.message, {
        autoClose: 2000,
        type: "warning",
      });
    }
  };

  const getHistoria = async (id) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/history/${id}`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    if (data.data.length === 0) {
      dispatch({
        type: HIST_SUCCESS,
        payload: {
          historia: [],
          message: null,
        },
      });
    } else {
      dispatch({
        type: HIST_SUCCESS,
        payload: {
          historia: data.data[0],
          message: null,
        },
      });
    }
  };

  const addHistory = async (hist) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(hist),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/history`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: HIST_SUCCESS,
      payload: {
        historia: data.data,
        message: data.message,
      },
    });
  };

  const updateHistory = async (hist, id) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));
    const config = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(hist),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/history/${id}`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: HIST_SUCCESS,
      payload: {
        historia: [],
        message: data.message,
      },
    });
  };

  const addEvolution = async (evo) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(evo),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/evolution`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: EVO_SUCCESS,
      payload: {
        evolution: data.data,
        message: data.message,
      },
    });
  };

  const updateEvolution = async (evo, id) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));
    const config = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(evo),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/evolution/${id}`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: EVO_SUCCESS,
      payload: {
        evolution: [],
        message: data.message,
      },
    });
  };

  const selEvolution = (evolution) => {
    dispatch({
      type: LOADING,
    });

    dispatch({
      type: EVO_SUCCESS,
      payload: {
        evolution,
        message: null,
      },
    });
  };

  const printEvolution = async (evo) => {
    try {
      dispatch({
        type: LOADING,
      });

      await axios
        .post(`${URIPDF}/evolution`, evo)
        .then(() =>
          axios.get(`${URIPDF}/pdf/evolution/${evo.tipo}`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const dblob = new Blob([res.data], { type: "application/pdf" });

          saveAs(dblob, `${evo.idevo}`);
          toast(`generado documento nota de evolución ${evo.idevo}.pdf`, {
            autoClose: 2000,
            type: "success",
          });
          dispatch({
            type: SUCCESS,
            payload: {
              message: `generado documento nota de evolución ${evo.idevo}.pdf`,
            },
          });
        });
    } catch (error) {
      dispatch({
        type: FAIL,
        payload: {
          message: error.message,
        },
      });
      toast(error.message, {
        autoClose: 2000,
        type: "warning",
      });
    }
  };

  const addCita = async (cita) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(cita),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/schedule`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });
      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: SUCCESS,
      payload: {
        message: data.message,
      },
    });
  };

  const updateCita = async (cita, id) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));
    const config = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(cita),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/schedule/${id}`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: CITA_SUCCESS,
      payload: {
        cita: [],
        message: data.message,
      },
    });
  };

  const delCita = async (id) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/schedule/${id}`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });

      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: CITA_SUCCESS,
      payload: {
        cita: [],
        message: data.message,
      },
    });
  };

  const selCita = async (data) => {
    dispatch({
      type: LOADING,
    });

    dispatch({
      type: CITA_SUCCESS,
      payload: {
        cita: data,
      },
    });
  };

  const addPresc = async (presc) => {
    dispatch({
      type: LOADING,
    });

    const { token } = JSON.parse(localStorage.getItem("userSession"));
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "cmd-token-access": token,
      },
      body: JSON.stringify(presc),
      cache: "no-cache",
    };

    const res = await fetch(`${URIROOT}/prescription`, config);
    const data = await res.json();

    if (!data.success) {
      toast(data.error || data.message, {
        autoClose: 2000,
        type: "error",
      });
      dispatch({
        type: FAIL,
        payload: {
          message: data.error || data.message,
        },
      });
    }

    toast(data.message, {
      autoClose: 2000,
      type: "success",
    });

    dispatch({
      type: SUCCESS,
      payload: {
        message: data.message,
      },
    });
  };

  const printPresc = async (presc) => {
    try {
      dispatch({
        type: LOADING,
      });

      await axios
        .post(`${URIPDF}/prescription`, presc)
        .then(() =>
          axios.get(`${URIPDF}/pdf/prescription/${presc.tipo}`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const dblob = new Blob([res.data], { type: "application/pdf" });

          saveAs(dblob, `${presc.idpresc}`);
          toast(`generado documento receta ${presc.idpresc}.pdf`, {
            autoClose: 2000,
            type: "success",
          });
          dispatch({
            type: SUCCESS,
            payload: {
              message: `generado documento receta ${presc.idpresc}.pdf`,
            },
          });
        });
    } catch (error) {
      dispatch({
        type: FAIL,
        payload: {
          message: error.message,
        },
      });
      toast(error.message, {
        autoClose: 2000,
        type: "warning",
      });
    }
  };

  const printCertNSano = async (datacert) => {
    try {
      dispatch({
        type: LOADING,
      });

      await axios
        .post(`${URIPDF}/certificado/nsano`, datacert)
        .then(() =>
          axios.get(`${URIPDF}/pdf/certificado/nsano`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const dblob = new Blob([res.data], { type: "application/pdf" });

          saveAs(dblob, `${datacert.idcert}`);
          toast(`generado documento certificado ${datacert.idcert}.pdf`, {
            autoClose: 2000,
            type: "success",
          });
          dispatch({
            type: SUCCESS,
            payload: {
              message: `generado documento certificado ${datacert.idcert}.pdf`,
            },
          });
        });
    } catch (error) {
      dispatch({
        type: FAIL,
        payload: {
          message: error.message,
        },
      });
      toast(error.message, {
        autoClose: 2000,
        type: "warning",
      });
    }
  };

  const printCertEnf = async (datacert) => {
    try {
      dispatch({
        type: LOADING,
      });

      await axios
        .post(`${URIPDF}/certificado/enfermedad`, datacert)
        .then(() =>
          axios.get(`${URIPDF}/pdf/certificado/enferm`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const dblob = new Blob([res.data], { type: "application/pdf" });

          saveAs(dblob, `${datacert.idcert}`);
          toast(`generado documento certificado ${datacert.idcert}.pdf`, {
            autoClose: 2000,
            type: "success",
          });
          dispatch({
            type: SUCCESS,
            payload: {
              message: `generado documento certificado ${datacert.idcert}.pdf`,
            },
          });
        });
    } catch (error) {
      dispatch({
        type: FAIL,
        payload: {
          message: error.message,
        },
      });
      toast(error.message, {
        autoClose: 2000,
        type: "warning",
      });
    }
  };

  const printCertRep = async (datacert) => {
    try {
      dispatch({
        type: LOADING,
      });

      await axios
        .post(`${URIPDF}/certificado/reposo`, datacert)
        .then(() =>
          axios.get(`${URIPDF}/pdf/certificado/reposo`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const dblob = new Blob([res.data], { type: "application/pdf" });

          saveAs(dblob, `${datacert.idcert}`);
          toast(`generado documento certificado ${datacert.idcert}.pdf`, {
            autoClose: 2000,
            type: "success",
          });
          dispatch({
            type: SUCCESS,
            payload: {
              message: `generado documento certificado ${datacert.idcert}.pdf`,
            },
          });
        });
    } catch (error) {
      dispatch({
        type: FAIL,
        payload: {
          message: error.message,
        },
      });
      toast(error.message, {
        autoClose: 2000,
        type: "warning",
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        auth: state.auth,
        loading: state.loading,
        token: state.token,
        login,
        logout,
        signup,
        updateUser,
        validateToken,
        printCertNSano,
        printCertEnf,
        printCertRep,
        patient: {
          patient: state.patient,
          addPatient,
          updatePatient,
          selPatient,
        },
        historia: {
          historia: state.historia,
          getHistoria,
          selHistory,
          addHistory,
          updateHistory,
          printHistory,
        },
        evolution: {
          evolution: state.evolution,
          addEvolution,
          selEvolution,
          updateEvolution,
          printEvolution,
        },
        cita: {
          cita: state.cita,
          addCita,
          selCita,
          updateCita,
          delCita,
        },
        presc: {
          addPresc,
          printPresc,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserState;
